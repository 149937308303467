import CardTitle from "@dashboard/components/CardTitle/CardTitle";
import { Grid } from "@dashboard/components/Grid";
import { RadioGroupField } from "@dashboard/components/RadioGroupField";
import { OrderValutaInput, PaymentType } from "@dashboard/graphql";
import { Button, CardContent, TextField } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
interface OrderPaymentTypeProps {
  orderValuta: OrderValutaInput;
  onOrderValutaEdit: (data: OrderValutaInput) => void;
}
export const OrderPaymentType = ({
  orderValuta,
  onOrderValutaEdit,
}: OrderPaymentTypeProps) => {
  const [formData, setFormData] = useState<OrderValutaInput>(orderValuta);

  useEffect(() => {
    setFormData(orderValuta);
  }, [orderValuta]);

  const noChanges = useMemo(
    () =>
      formData?.reason === orderValuta?.reason &&
      formData?.type === orderValuta?.type,
    [orderValuta, formData],
  );

  const submitOrderValutaEdit = () => {
    onOrderValutaEdit({
      type: formData.type,
      reason: formData.reason,
      value: formData?.value,
    } as any);
  };

  return (
    <div style={{ width: "100%" }}>
      <CardTitle title="Payment Type" />
      <CardContent>
        <Grid variant="uniform">
          <RadioGroupField
            choices={[
              { label: PaymentType.DELAY, value: PaymentType.DELAY },
              { label: PaymentType.IN_ADVANCE, value: PaymentType.IN_ADVANCE },
              {
                label: PaymentType.ON_DELIVERY,
                value: PaymentType.ON_DELIVERY,
              },
            ]}
            disabled={false}
            error={null}
            name="type"
            value={formData?.type}
            onChange={e => {
              setFormData(formData => ({
                ...formData,
                type: e.currentTarget?.value,
              }));
            }}
          />
        </Grid>
        {/** If order payment needs to be delay, give reason. */}
        {formData?.type === PaymentType.DELAY && (
          <>
            <TextField
              label="Reason"
              type="text"
              style={{ width: "100%" }}
              value={formData?.reason}
              onChange={e => {
                setFormData(formData => ({
                  ...formData,
                  reason: e.target?.value,
                }));
              }}
            />
            <TextField
              label="Value (days)"
              type="number"
              style={{ width: "100%", marginTop: "15px" }}
              value={formData?.value || 15}
              onChange={e => {
                if (!Number.isFinite(+e.target.value)) {
                  return;
                }
                setFormData(formData => ({
                  ...formData,
                  value: +e.target?.value,
                }));
              }}
            />
          </>
        )}
        <Button
          disabled={noChanges}
          title={noChanges ? "There is no changes" : "Submit changes"}
          onClick={submitOrderValutaEdit}
          style={{ float: "right", marginTop: "10px" }}
        >
          Save
        </Button>
      </CardContent>
    </div>
  );
};

export default OrderPaymentType;
