import ResponsiveTable from "@dashboard/components/ResponsiveTable";
import TableCellHeader from "@dashboard/components/TableCellHeader/TableCellHeader";
import TableRowLink from "@dashboard/components/TableRowLink/TableRowLink";
import { groupDetailsUrl, GroupsUrlSortField } from "@dashboard/discounts/urls";
import { CustomerGroupsQuery } from "@dashboard/graphql";
import { maybe, renderCollection } from "@dashboard/misc";
import { useStyles } from "@dashboard/orders/components/OrderList/OrderList";
import {
  ChannelProps,
  ListActions,
  ListProps,
  SortPage,
} from "@dashboard/types";
import { getArrowDirection } from "@dashboard/utils/sort";
import { TableBody, TableCell, TableHead } from "@material-ui/core";
import React from "react";
import { FormattedDate } from "react-intl";

export interface GroupsListProps
  extends ListProps,
    ListActions,
    SortPage<GroupsUrlSortField>,
    ChannelProps {
  groups: CustomerGroupsQuery["customerGroups"]["edges"];
}

export const GroupsList: React.FC<GroupsListProps> = props => {
  const { groups, sort, onSort } = props;
  const classes = useStyles(props);

  return (
    <ResponsiveTable>
      {/** HEAD */}
      <TableHead>
        <TableRowLink>
          <TableCellHeader
            direction={
              sort.sort === GroupsUrlSortField.NAME
                ? getArrowDirection(sort.asc)
                : undefined
            }
            onClick={() => onSort(GroupsUrlSortField.NAME)}
            arrowPosition="right"
            className={classes.colNumber}
          >
            Name
          </TableCellHeader>
          <TableCellHeader
            direction={undefined}
            arrowPosition="right"
            className={classes.colNumber}
          >
            Voucher code
          </TableCellHeader>
          <TableCellHeader
            direction={undefined}
            arrowPosition="right"
            className={classes.colNumber}
          >
            Channel
          </TableCellHeader>
          <TableCellHeader
            direction={
              sort.sort === GroupsUrlSortField.START_DATE
                ? getArrowDirection(sort.asc)
                : undefined
            }
            onClick={() => onSort(GroupsUrlSortField.START_DATE)}
            arrowPosition="right"
            className={classes.colNumber}
          >
            Start Date
          </TableCellHeader>
          <TableCellHeader
            direction={
              sort.sort === GroupsUrlSortField.END_DATE
                ? getArrowDirection(sort.asc)
                : undefined
            }
            onClick={() => onSort(GroupsUrlSortField.END_DATE)}
            arrowPosition="right"
            className={classes.colNumber}
          >
            End Date
          </TableCellHeader>
        </TableRowLink>
      </TableHead>
      {/** BODY */}
      <TableBody>
        {renderCollection(groups, group => (
          <TableRowLink
            className={!!group ? classes.tableRow : undefined}
            hover={!!group}
            key={group ? group?.node?.id : "skeleton"}
            href={group ? groupDetailsUrl(group.node?.id as any) : "/"}
          >
            <TableCell className={classes.colNumber}>
              {maybe<React.ReactNode>(() => group.node?.name)}
            </TableCell>
            <TableCell className={classes.colNumber}>
              {maybe<React.ReactNode>(() => group.node?.voucher?.code)}
            </TableCell>
            <TableCell className={classes.colNumber}>
              {" "}
              {maybe<React.ReactNode>(() => group.node?.channel?.name)}
            </TableCell>
            <TableCell className={classes.colNumber}>
              {maybe<React.ReactNode>(() =>
                FormattedDate(group.node?.voucher?.startDate),
              )}
            </TableCell>
            <TableCell className={classes.colNumber}>
              {maybe<React.ReactNode>(() =>
                FormattedDate(group.node?.voucher?.endDate),
              )}
            </TableCell>
          </TableRowLink>
        ))}
      </TableBody>
    </ResponsiveTable>
  );
};

export default GroupsList;
