import SearchBar from "@dashboard/components/SearchBar/SearchBar";
import { useSearchCategoriesQuery } from "@dashboard/graphql";
import { FormChange } from "@dashboard/hooks/useForm";
import { Button } from "@material-ui/core";
import { DiscountValueTypeEnum } from "@saleor/sdk/dist/apollo/types";
import React, { useState } from "react";
import { useIntl } from "react-intl";

import GroupCategoriesList from "../GroupCategoriesList/GroupCategoriesList";
// Interface
interface GroupCategoriesProps {
  discountedCategories: Array<{
    id: string;
    discountOptions: { value: string; valueType: DiscountValueTypeEnum };
  }>;
  onChange: FormChange<any>;
}
const GroupCategories = ({
  discountedCategories,
  onChange = () => {},
}: GroupCategoriesProps) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const intl = useIntl();
  const [paginationData, setPaginationData] = useState<any>({ first: 20 });
  const queryVariables = React.useMemo(
    () => ({
      query: searchValue,
      ...paginationData,
    }),
    [searchValue, paginationData],
  );

  const { data, error, loading } = useSearchCategoriesQuery({
    displayLoader: true,
    variables: queryVariables,
  });

  const formatCategory = (category: any) => ({
    id: category?.id,
    discountOptions: {
      value: 0,
      valueType: "PERCENTAGE" as DiscountValueTypeEnum,
    },
  });

  const onAssignCategory = (category: any) => {
    const categoryID = category?.id;
    const alreadyExists = discountedCategories?.some(
      selectedCategory => selectedCategory.id === categoryID,
    );
    if (alreadyExists) {
      return;
    }

    //
    onChange({
      target: {
        name: "discountedCategories",
        value: [...discountedCategories, formatCategory(category)],
      },
    });
  };

  const onUnassignCategory = (category: any) => {
    const categoryID = category?.id;

    //
    onChange({
      target: {
        name: "discountedCategories",
        value: discountedCategories?.filter(
          selectedCategory => selectedCategory?.id !== categoryID,
        ),
      },
    });
  };

  const toggleAll = (categories: any, type: "assign" | "unassign") => {
    if (type === "assign") {
      onChange({
        target: {
          name: "discountedCategories",
          value: [
            ...discountedCategories,
            ...categories
              ?.filter(
                category =>
                  !discountedCategories?.some(
                    selectedCategory => selectedCategory?.id === category?.id,
                  ),
              )
              ?.map(category => formatCategory(category)),
          ],
        },
      });
    } else {
      onChange({
        target: {
          name: "discountedCategories",
          value: discountedCategories?.filter(
            selectedCategory =>
              !categories.some(
                category => category.id === selectedCategory?.id,
              ),
          ),
        },
      });
    }
  };

  const updateCategory = (
    category: any,
    discountOptions: { value: number; valueType: DiscountValueTypeEnum },
  ) => {
    const categoryID = category?.id;
    //
    onChange({
      target: {
        name: "discountedCategories",
        value: discountedCategories?.map(selectedCategory => {
          if (selectedCategory?.id === categoryID) {
            return {
              ...selectedCategory,
              discountOptions,
            };
          } else {
            return selectedCategory;
          }
        }),
      },
    });
  };

  const bulkUpdateCategories = (
    categories: any,
    discountOptions: { value: number; valueType: DiscountValueTypeEnum },
  ) => {
    onChange({
      target: {
        name: "discountedCategories",
        value: categories?.map(selectedCategory => ({
          ...selectedCategory,
          discountOptions: {
            value: discountOptions?.value
              ? discountOptions?.value
              : selectedCategory?.discountOptions.value,
            valueType: discountOptions?.valueType
              ? discountOptions?.valueType
              : selectedCategory?.discountOptions.valueType,
          },
        })),
      },
    });
  };

  return (
    <div>
      {" "}
      <SearchBar
        allTabLabel={intl.formatMessage({
          id: "vy7fjd",
          defaultMessage: "All Categories",
          description: "tab name",
        })}
        currentTab={null}
        initialSearch={searchValue}
        searchPlaceholder={intl.formatMessage({
          id: "JiXNEV",
          defaultMessage: "Search Category",
        })}
        tabs={[]}
        onAll={() => {}}
        onSearchChange={(value: string) => setSearchValue(value)}
        onTabChange={() => {}}
        onTabDelete={() => {
          setSearchValue("");
        }}
        onTabSave={() => {}}
      />
      {loading ? (
        "Loading..."
      ) : (
        <>
          {" "}
          <GroupCategoriesList
            categories={
              data?.search?.edges?.map(category => ({
                ...category?.node,
              })) as any
            }
            selectedCategories={discountedCategories}
            disabled={error ? true : false}
            onAssignCategory={onAssignCategory}
            onUnassignCategory={onUnassignCategory}
            toggleAll={toggleAll}
            updateCategory={updateCategory}
            bulkUpdateCategories={bulkUpdateCategories}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            {data?.search?.pageInfo?.hasPreviousPage ? (
              <Button
                style={{ marginRight: "15px" }}
                onClick={() => {
                  setPaginationData({
                    before: data?.search?.pageInfo?.startCursor,
                    first: 20,
                  });
                }}
              >
                Back to first page
              </Button>
            ) : (
              <></>
            )}
            {data?.search?.pageInfo?.hasNextPage ? (
              <Button
                onClick={() => {
                  setPaginationData({
                    after: data?.search?.pageInfo?.endCursor,
                    first: 20,
                  });
                }}
              >
                Next
              </Button>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default GroupCategories;
