import Checkbox from "@dashboard/components/Checkbox/Checkbox";
import Skeleton from "@dashboard/components/Skeleton";
import TableCellHeader from "@dashboard/components/TableCellHeader/TableCellHeader";
import TableHead from "@dashboard/components/TableHead/TableHead";
import { renderCollection } from "@dashboard/misc";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
import { makeStyles, ResponsiveTable } from "@saleor/macaw-ui";
import React from "react";
// Interface
interface ProductRealtedsListProps {
  products: any[];
  selectedProducts: Array<{ id: string }>;
  disabled?: boolean;
  onAssignProduct: (product: any) => void;
  onUnassignProduct: (product: any) => void;
  toggleAll: (products: any[], type: "assign" | "unassign") => void;
}

const useStyles = makeStyles(
  theme => ({
    [theme.breakpoints.up("lg")]: {
      colName: {
        width: "auto",
      },
      colProducts: {
        width: 160,
      },
      colSubcategories: {
        width: 160,
      },
    },
    colName: {
      paddingLeft: 0,
    },
    colProducts: {
      textAlign: "center",
    },
    colSubcategories: {
      textAlign: "center",
    },
    tableRow: {
      cursor: "pointer",
    },
  }),
  { name: "ProductList" },
);

const ProductRelatedsList = ({
  products,
  disabled,
  selectedProducts,
  onAssignProduct,
  onUnassignProduct,
}: ProductRealtedsListProps) => {
  const classes = useStyles();
  return (
    <ResponsiveTable>
      <TableHead
        items={products}
        disabled={disabled}
        selected={selectedProducts?.length}
        colSpan={4}
        toggleAll={undefined}
      >
        <TableCellHeader>Image</TableCellHeader>
        <TableCellHeader>Name</TableCellHeader>
      </TableHead>
      <TableBody>
        {renderCollection(products, product => {
          const isProductChecked = selectedProducts?.some(
            (selectedProduct: any) => selectedProduct?.id === product?.node?.id,
          );

          return (
            <TableRow className={classes?.tableRow} key={product?.node?.id}>
              <TableCell padding="checkbox">
                <Checkbox
                  disabled={disabled}
                  disableClickPropagation
                  checked={isProductChecked}
                  onChange={() => {
                    if (isProductChecked) {
                      onUnassignProduct(product?.node);
                    } else {
                      onAssignProduct(product?.node);
                    }
                  }}
                />
              </TableCell>
              <TableCell>
                <img
                  src={
                    product?.node?.thumbnail?.url?.startsWith("https")
                      ? product?.node?.thumbnail?.url
                      : product?.node?.thumbnail?.url?.replaceAll(
                          "http",
                          "https",
                        )
                  }
                  style={{
                    width: "100%",
                    height: "100%",
                    minWidth: "60px",
                    minHeight: "60px",
                    maxHeight: "60px",
                    maxWidth: "60px",
                    objectFit: "cover",
                  }}
                />
              </TableCell>
              <TableCell>{product?.node?.name || <Skeleton />}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </ResponsiveTable>
  );
};

export default ProductRelatedsList;
