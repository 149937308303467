import CardTitle from "@dashboard/components/CardTitle/CardTitle";
import { useRequestPasswordResetMutation } from "@dashboard/graphql";
import useNotifier from "@dashboard/hooks/useNotifier/useNotifier";
import { Button, Card, CardContent } from "@material-ui/core";
import React, { useState } from "react";
interface Props {
  customerEmail: string;
}
export const SendPasswordRequest = ({ customerEmail }: Props) => {
  const notify = useNotifier();
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [requestPasswordReset] = useRequestPasswordResetMutation();

  const sendPasswordRequest = async (customerEmail: string) => {
    try {
      if (!customerEmail) {
        notify({
          status: "warning",
          text: "Customer has no email, first add it.",
        });
        return;
      }
      if (!customerEmail.includes("@")) {
        notify({
          status: "warning",
          text: "Wrong email, email must have @",
        });
        return;
      }

      setLoading(true);
      const { data } = await requestPasswordReset({
        variables: {
          email: customerEmail,
          redirectUrl: `https://aqualing-front.vercel.app/default-channel/en-US/account/resetPassword`,
        },
      });
      if (data.requestPasswordReset.errors?.length > 0) {
        notify({
          status: "error",
          text: data.requestPasswordReset.errors[0]?.message,
        });
        setLoading(false);
        return;
      }
      notify({
        status: "success",
        text: "You successfully sent request password change",
      });
      setLoading(false);
      setSent(true);
    } catch (err) {
      setLoading(false);
      notify({
        status: "error",
        text: "Something went wrong!",
      });
    }
  };

  return (
    <Card>
      <CardTitle title="You wanna send password request to customer?" />
      <CardContent>
        {sent ? (
          <p style={{ color: "limegreen" }}>You sent request</p>
        ) : (
          <Button
            onClick={() => sendPasswordRequest(customerEmail)}
            title="Send password request"
          >
            {loading ? "Processing..." : "   Send password request"}
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default SendPasswordRequest;
