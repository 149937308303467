import { DateTime } from "@dashboard/components/Date";
// Components
import ResponsiveTable from "@dashboard/components/ResponsiveTable";
import Skeleton from "@dashboard/components/Skeleton";
import TableCellHeader from "@dashboard/components/TableCellHeader";
import { TablePaginationWithContext } from "@dashboard/components/TablePagination";
import TableRowLink from "@dashboard/components/TableRowLink";
import { OrderComplaintListQuery } from "@dashboard/graphql";
import { maybe, renderCollection } from "@dashboard/misc";
import { useStyles } from "@dashboard/orders/components/OrderList/OrderList";
import {
  OrderComplaintListUrlSortField,
  orderComplaintUrl,
} from "@dashboard/orders/urls";
// Other
import { ListProps, SortPage } from "@dashboard/types";
import { getArrowDirection } from "@dashboard/utils/sort";
// MUI
import {
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
} from "@material-ui/core";
import { LocalShipping, StoreOutlined } from "@material-ui/icons";
import { Pill } from "@saleor/macaw-ui";
import React, { memo } from "react";
import { FormattedMessage } from "react-intl";
interface OrderComplaintListProps
  extends ListProps,
    SortPage<OrderComplaintListUrlSortField> {
  orders: OrderComplaintListQuery["returnables"]["edges"];
}
export const OrderComplaintList: React.FC<OrderComplaintListProps> = props => {
  const { orders, disabled, onSort, sort, onUpdateListSettings, settings } =
    props;

  const classes = useStyles(props);

  return (
    <ResponsiveTable>
      {/** HEAD OF TABLE */}
      <TableHead>
        <TableRowLink>
          {/** Order number */}
          <TableCellHeader
            direction={undefined}
            arrowPosition="right"
            onClick={() => () => {}}
            className={classes.colNumber}
          >
            <FormattedMessage id="JAcwxt" defaultMessage="Order Number" />
          </TableCellHeader>
          {/** Customer */}
          <TableCellHeader
            direction={
              sort.sort === OrderComplaintListUrlSortField.created
                ? getArrowDirection(sort.asc)
                : undefined
            }
            onClick={() => onSort(OrderComplaintListUrlSortField.created)}
            className={classes.colNumber}
            arrowPosition="left"
          >
            <FormattedMessage id="ORGv1Q" defaultMessage="Created" />
          </TableCellHeader>
          {/** Customer */}
          <TableCellHeader
            direction={undefined}
            onClick={() => {}}
            className={classes.colNumber}
            arrowPosition="left"
          >
            <FormattedMessage id="hkENym" defaultMessage="Customer" />
          </TableCellHeader>
          {/** Return type */}
          <TableCellHeader
            direction={
              sort.sort === OrderComplaintListUrlSortField.returnType
                ? getArrowDirection(sort.asc)
                : undefined
            }
            onClick={() => onSort(OrderComplaintListUrlSortField.returnType)}
            className={classes.colNumber}
            arrowPosition="left"
          >
            <FormattedMessage id="cMh52k" defaultMessage="Return Type" />
          </TableCellHeader>
          {/** Return method */}
          <TableCellHeader
            direction={undefined}
            className={classes.colNumber}
            arrowPosition="left"
          >
            <FormattedMessage id="+3E8/2" defaultMessage="Return Method" />
          </TableCellHeader>
          {/** Completed */}
          <TableCellHeader
            direction={
              sort.sort === OrderComplaintListUrlSortField.orderStatus
                ? getArrowDirection(sort.asc)
                : undefined
            }
            onClick={() => onSort(OrderComplaintListUrlSortField.orderStatus)}
            className={classes.colNumber}
            arrowPosition="left"
          >
            <FormattedMessage id="95stPq" defaultMessage="Completed" />
          </TableCellHeader>
        </TableRowLink>
      </TableHead>
      {/** Footer */}
      <TableFooter>
        <TableRowLink>
          <TablePaginationWithContext
            colSpan={6}
            settings={settings}
            disabled={disabled}
            onUpdateListSettings={onUpdateListSettings}
          />
        </TableRowLink>
      </TableFooter>
      {/** BODY OF TABLE */}
      <TableBody>
        {orders ? (
          renderCollection(orders, order => (
            <TableRowLink
              data-test-id="order-complaints-row"
              hover={!!order}
              className={!!order ? classes.link : undefined}
              href={
                order?.node.id ? orderComplaintUrl(order?.node?.id as any) : "#"
              }
              key={order ? order.node.id : "skeleton"}
            >
              {/** Order number */}
              <TableCell className={classes.colNumber}>
                {maybe(() =>
                  order?.node?.id ? (
                    `#${order.node.order.number}`
                  ) : (
                    <Skeleton />
                  ),
                )}
              </TableCell>
              {/** Date */}
              <TableCell className={classes.colNumber}>
                {maybe(() =>
                  order?.node?.createdAt ? (
                    <DateTime date={order.node.createdAt} plain />
                  ) : (
                    <Skeleton />
                  ),
                )}
              </TableCell>
              {/** Customer */}
              <TableCell className={classes.colNumber}>
                {maybe(() =>
                  order?.node?.order?.billingAddress ? (
                    `${order.node.order?.billingAddress?.firstName} ${order.node.order?.billingAddress?.lastName}`
                  ) : (
                    <Skeleton />
                  ),
                )}
              </TableCell>
              {/** Return type */}
              <TableCell className={classes.colNumber}>
                {maybe(() =>
                  order?.node?.returnType ? (
                    <Pill
                      className={classes.pill}
                      color={
                        order?.node?.returnType === "complaint"
                          ? "success"
                          : "warning"
                      }
                      label={order?.node?.returnType}
                    />
                  ) : (
                    <Skeleton />
                  ),
                )}
              </TableCell>
              {/** Return method */}
              <TableCell className={classes.colNumber}>
                {maybe(() =>
                  order?.node?.returnMethod ? (
                    order.node.returnMethod === "BY_POST" ? (
                      <div className={classes.returnMethod}>
                        <LocalShipping /> <p>BY POST</p>
                      </div>
                    ) : (
                      <div className={classes.returnMethod}>
                        <StoreOutlined />
                        <p>RETURN TO SHOP</p>
                      </div>
                    )
                  ) : (
                    <Skeleton />
                  ),
                )}
              </TableCell>
              {/** Completed */}
              <TableCell className={classes.colNumber}>
                {order.node.order.status === "RETURNED" ? "✅" : "❌"}
              </TableCell>
            </TableRowLink>
          ))
        ) : (
          <></>
        )}
      </TableBody>
    </ResponsiveTable>
  );
};
/* eslint-disable */
export default memo(OrderComplaintList);
/* eslint-enable */
