import ActionDialog from "@dashboard/components/ActionDialog";
import DeleteFilterTabDialog from "@dashboard/components/DeleteFilterTabDialog";
import SaveFilterTabDialog, {
  SaveFilterTabDialogFormData,
} from "@dashboard/components/SaveFilterTabDialog";
import { groupAddUrl } from "@dashboard/discounts/urls";
import {
  CategoryBulkDeleteMutation,
  useCategoryBulkDeleteMutation,
  useRootCategoriesQuery,
} from "@dashboard/graphql";
import useBulkActions from "@dashboard/hooks/useBulkActions";
import useListSettings from "@dashboard/hooks/useListSettings";
import useNavigator from "@dashboard/hooks/useNavigator";
import { usePaginationReset } from "@dashboard/hooks/usePaginationReset";
import usePaginator, {
  createPaginationState,
  PaginatorContext,
} from "@dashboard/hooks/usePaginator";
import { maybe } from "@dashboard/misc";
import { ListViews } from "@dashboard/types";
import createDialogActionHandlers from "@dashboard/utils/handlers/dialogActionHandlers";
import createSortHandler from "@dashboard/utils/handlers/sortHandler";
import { mapEdgesToItems } from "@dashboard/utils/maps";
import { getSortParams } from "@dashboard/utils/sort";
import { DialogContentText } from "@material-ui/core";
import { DeleteIcon, IconButton } from "@saleor/macaw-ui";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { CategoryListPage } from "../../components/CategoryListPage/CategoryListPage";
import {
  categoryListUrl,
  CategoryListUrlDialog,
  CategoryListUrlFilters,
  CategoryListUrlQueryParams,
} from "../../urls";
import {
  deleteFilterTab,
  getActiveFilters,
  getFiltersCurrentTab,
  getFilterTabs,
  getFilterVariables,
  saveFilterTab,
} from "./filter";
import { getSortQueryVariables } from "./sort";

interface CategoryListProps {
  params: CategoryListUrlQueryParams;
  usingAsGroupCategories?: boolean;
  onCategorySelect?: (id: any) => void;
  onToggleAll?: (
    selectedItems: any[],
    numOfSelectedItems: number,
    set: (data: string[]) => void,
  ) => void;
  customIsChecked?: (id: any) => any;
}

export const CategoryList: React.FC<CategoryListProps> = ({
  params,
  usingAsGroupCategories,
  onCategorySelect = () => {}, // eslint-disable-line
  customIsChecked = null,
  onToggleAll = () => {},
}) => {
  const navigate = useNavigator();
  const { isSelected, listElements, toggle, toggleAll, reset, set } =
    useBulkActions(params.ids);
  const { updateListSettings, settings } = useListSettings(
    ListViews.CATEGORY_LIST,
  );

  usePaginationReset(
    usingAsGroupCategories ? (groupAddUrl as any) : categoryListUrl,
    params,
    settings.rowNumber,
  );

  const intl = useIntl();

  const paginationState = createPaginationState(settings.rowNumber, params);
  const queryVariables = React.useMemo(
    () => ({
      ...paginationState,
      filter: getFilterVariables(params),
      sort: getSortQueryVariables(params),
    }),
    [params, settings.rowNumber], // eslint-disable-line
  );
  const { data, loading, refetch } = useRootCategoriesQuery({
    displayLoader: true,
    variables: queryVariables,
  });

  const tabs = getFilterTabs();

  const currentTab = getFiltersCurrentTab(params, tabs);

  const changeFilterField = (filter: CategoryListUrlFilters) => {
    reset();
    navigate(
      usingAsGroupCategories
        ? groupAddUrl({
            ...getActiveFilters(params),
            ...filter,
            activeTab: undefined,
          })
        : categoryListUrl({
            ...getActiveFilters(params),
            ...filter,
            activeTab: undefined,
          }),
    );
  };

  const [openModal, closeModal] = createDialogActionHandlers<
    CategoryListUrlDialog,
    CategoryListUrlQueryParams
  >(
    navigate,
    usingAsGroupCategories ? (groupAddUrl as any) : categoryListUrl,
    params,
  );

  const handleTabChange = (tab: number) => {
    reset();
    navigate(
      usingAsGroupCategories
        ? groupAddUrl({
            activeTab: tab.toString(),
            ...getFilterTabs()[tab - 1].data,
          })
        : categoryListUrl({
            activeTab: tab.toString(),
            ...getFilterTabs()[tab - 1].data,
          }),
    );
  };

  const handleTabDelete = () => {
    deleteFilterTab(currentTab);
    reset();
    navigate(usingAsGroupCategories ? (groupAddUrl as any) : categoryListUrl());
  };

  const handleTabSave = (data: SaveFilterTabDialogFormData) => {
    saveFilterTab(data.name, getActiveFilters(params));
    handleTabChange(tabs.length + 1);
  };

  const paginationValues = usePaginator({
    pageInfo: maybe(() => data.categories.pageInfo),
    paginationState,
    queryString: params,
  });

  const handleCategoryBulkDelete = (data: CategoryBulkDeleteMutation) => {
    if (data.categoryBulkDelete.errors.length === 0) {
      navigate(usingAsGroupCategories ? groupAddUrl() : categoryListUrl(), {
        replace: true,
      });
      refetch();
      reset();
    }
  };

  const [categoryBulkDelete, categoryBulkDeleteOpts] =
    useCategoryBulkDeleteMutation({
      onCompleted: handleCategoryBulkDelete,
    });

  const handleSort = createSortHandler(
    navigate,
    usingAsGroupCategories ? (groupAddUrl as any) : categoryListUrl,
    params,
  );

  return (
    <PaginatorContext.Provider value={paginationValues}>
      <CategoryListPage
        categories={mapEdgesToItems(data?.categories) as any}
        currentTab={currentTab}
        initialSearch={params.query || ""}
        onSearchChange={query => changeFilterField({ query })}
        onAll={() => navigate(categoryListUrl())}
        onTabChange={handleTabChange}
        onTabDelete={() => openModal("delete-search")}
        onTabSave={() => openModal("save-search")}
        tabs={tabs.map(tab => tab.name)}
        settings={settings}
        sort={getSortParams(params)}
        onSort={handleSort}
        disabled={loading}
        onUpdateListSettings={updateListSettings}
        isChecked={(id: any) => {
          if (customIsChecked) {
            return customIsChecked(id);
          } else {
            return isSelected(id);
          }
        }}
        selected={listElements.length}
        toggle={toggle}
        toggleAll={(selectedItems: any[], numOfSelectedItems: number) => {
          onToggleAll(selectedItems, numOfSelectedItems, set);
          return toggleAll(selectedItems, numOfSelectedItems);
        }}
        onCategorySelect={onCategorySelect}
        toolbar={
          <IconButton
            variant="secondary"
            color="primary"
            data-test-id="delete-icon"
            onClick={() =>
              openModal("delete", {
                ids: listElements,
              })
            }
          >
            <DeleteIcon />
          </IconButton>
        }
      />
      <ActionDialog
        confirmButtonState={categoryBulkDeleteOpts.status}
        onClose={() =>
          navigate(
            categoryListUrl({
              ...params,
              action: undefined,
              ids: undefined,
            }),
          )
        }
        onConfirm={() =>
          categoryBulkDelete({
            variables: {
              ids: params.ids,
            },
          })
        }
        open={params.action === "delete"}
        title={intl.formatMessage({
          id: "sG0w22",
          defaultMessage: "Delete categories",
          description: "dialog title",
        })}
        variant="delete"
      >
        <DialogContentText>
          <FormattedMessage
            id="Pp/7T7"
            defaultMessage="{counter,plural,one{Are you sure you want to delete this category?} other{Are you sure you want to delete {displayQuantity} categories?}}"
            values={{
              counter: maybe(() => params.ids.length),
              displayQuantity: (
                <strong>{maybe(() => params.ids.length)}</strong>
              ),
            }}
          />
        </DialogContentText>
        <DialogContentText>
          <FormattedMessage
            id="e+L+q3"
            defaultMessage="Remember this will also delete all products assigned to this category."
          />
        </DialogContentText>
      </ActionDialog>
      <SaveFilterTabDialog
        open={params.action === "save-search"}
        confirmButtonState="default"
        onClose={closeModal}
        onSubmit={handleTabSave}
      />
      <DeleteFilterTabDialog
        open={params.action === "delete-search"}
        confirmButtonState="default"
        onClose={closeModal}
        onSubmit={handleTabDelete}
        tabName={maybe(() => tabs[currentTab - 1].name, "...")}
      />
    </PaginatorContext.Provider>
  );
};
export default CategoryList;
