/* eslint-disable */
import {
  OrderErrorCode,
  useFulfillmentReturnProductsMutation,
  useOrderComplaintDataQuery,
} from "@dashboard/graphql";
import useNavigator from "@dashboard/hooks/useNavigator";
import useNotifier from "@dashboard/hooks/useNotifier";
import { commonMessages } from "@dashboard/intl";
import { extractMutationErrors } from "@dashboard/misc";
import OrderComplaintPage from "@dashboard/orders/components/OrderComplaintPage";
import { OrderReturnFormData } from "@dashboard/orders/components/OrderReturnPage/form";
import { orderComplaintListUrl } from "@dashboard/orders/urls";
import React from "react";
import { useIntl } from "react-intl";

import { messages } from "../OrderReturn/messages";
import ReturnFormDataParser from "../OrderReturn/utils";
/* eslint-enable */
interface OrderReturnProps {
  orderComplaintId: string;
}

const OrderReturn: React.FC<OrderReturnProps> = ({ orderComplaintId }) => {
  const navigate = useNavigator();
  const notify = useNotifier();
  const intl = useIntl();

  const { data, loading: orderComplaintDataLoading } =
    useOrderComplaintDataQuery({
      skip: !orderComplaintId,
      variables: {
        id: orderComplaintId as any,
      },
    });

  const [returnCreate, returnCreateOpts] = useFulfillmentReturnProductsMutation(
    {
      onCompleted: ({ orderFulfillmentReturnProducts: { errors } }) => {
        if (!errors.length) {
          notify({
            status: "success",
            text:
              data?.returnable?.returnType === "refund"
                ? "Successfully refund for products"
                : "Successfully returned products!",
          });

          navigate(orderComplaintListUrl());

          return;
        }

        if (errors.some(err => err.code === OrderErrorCode.CANNOT_REFUND)) {
          notify({
            autohide: 5000,
            status: "error",
            text: intl.formatMessage(messages.cannotRefundDescription),
            title: intl.formatMessage(messages.cannotRefundTitle),
          });

          return;
        }

        notify({
          autohide: 5000,
          status: "error",
          text: intl.formatMessage(commonMessages.somethingWentWrong),
        });
      },
    },
  );

  const handleSubmit = async (formData: OrderReturnFormData) => {
    if (!data?.returnable.order) {
      return;
    }

    return extractMutationErrors(
      returnCreate({
        variables: {
          id: data?.returnable.order.id,
          input: new ReturnFormDataParser({
            order: {
              ...data?.returnable.order,
              lines: data?.returnable.lineReturnables,
            },
            formData,
            refundsEnabled: data.returnable.returnType === "refund",
          }).getParsedData(),
        },
      }),
    );
  };

  return (
    <OrderComplaintPage
      orderComplaintData={data?.returnable}
      errors={returnCreateOpts?.data?.orderFulfillmentReturnProducts.errors}
      order={{
        ...data?.returnable.order,
        lines: data?.returnable?.lineReturnables,
      }}
      loading={returnCreateOpts?.loading || orderComplaintDataLoading}
      onSubmit={handleSubmit}
      submitStatus={returnCreateOpts.status}
    />
  );
};

OrderReturn.displayName = "OrderReturn";
export default OrderReturn;
