import complaintFinishedIMG from "@assets/images/complaint-finished.webp";
import React from "react";
// Interfaces
interface ComplaintFinishedProps {
  text: string;
}
export const ComplaintFinished = ({ text = "" }: ComplaintFinishedProps) => (
  // w-full p-2 flex items-center justify-center
  <div
    style={{
      width: "100%",
      padding: "1rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    }}
  >
    <img
      src={complaintFinishedIMG}
      loading="lazy"
      width="100%"
      height="100%"
      style={{ maxWidth: "250px" }}
    />
    <p style={{ fontSize: "16px", color: "#006AB0" }}>{text}</p>
  </div>
);
