import CardTitle from "@dashboard/components/CardTitle/CardTitle";
import { CHANNELS } from "@dashboard/discounts/utils";
import { Box, Card, CardContent, Checkbox, TextField } from "@material-ui/core";
import React from "react";

import { FormData } from "../GroupCreatePage";
interface GroupInfoProps {
  onChange: (event: any) => void;
  errors: any[];
  disabled: boolean;
  variant: "create" | "update";
  data: FormData;
}
export const GroupPageInfo = ({
  onChange,
  errors,
  disabled,
  variant,
  data,
}: GroupInfoProps) => (
  <Card>
    <CardTitle title={"Basic informations"} />
    <CardContent>
      <TextField
        label="Group Name"
        disabled={disabled || variant === "update"}
        error={errors?.find(error => error.field === "name") || null}
        fullWidth
        helperText={errors?.find(error => error.field === "name")?.message}
        value={data?.name}
        required={true}
        name="name"
        onChange={onChange}
      />
      <TextField
        label="Limit"
        disabled={disabled || variant === "update"}
        style={{ marginTop: "1em" }}
        error={errors?.find(error => error.field === "limit") || null}
        fullWidth
        helperText={errors?.find(error => error.field === "limit")?.message}
        value={data?.limit}
        required={true}
        name="limit"
        onChange={onChange}
      />
      <Box>
        <h3>Group Channel</h3>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            justifyContent: "flex-start",
          }}
        >
          {CHANNELS?.map(({ id, name }) => (
            <Box style={{ textAlign: "center" }}>
              <h4>{name}</h4>
              <Checkbox
                disabled={variant === "update"}
                onChange={e =>
                  onChange({
                    target: { name: "channel", value: e.target.value },
                  })
                }
                value={id}
                checked={id === data?.channel}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </CardContent>
  </Card>
);

export default GroupPageInfo;
