import { DEFAULT_INITIAL_PAGINATION_DATA } from "@dashboard/config";
import { Pagination } from "@dashboard/types";
import { useEffect } from "react";

import useNavigator from "./useNavigator";

export function usePaginationReset<T extends Pagination>(
  urlFunc: (...params: any) => string,
  params: T,
  rowNumber: number,
) {
  const navigate = useNavigator();
  /* eslint-disable */
  useEffect(() => {
    if ((params as any)?.id) {
      navigate(
        urlFunc((params as any).id, {
          ...params,
          ...DEFAULT_INITIAL_PAGINATION_DATA,
        }),
        { replace: true },
      );
    } else {
      navigate(
        urlFunc({
          ...params,
          ...DEFAULT_INITIAL_PAGINATION_DATA,
        }),
        { replace: true },
      );
    }
  }, [rowNumber]);

  useEffect(() => {
    if ((params as any)?.id) {
      navigate(
        urlFunc((params as any).id, {
          ...params,
        }),
        { replace: true },
      );
    } else {
      navigate(
        urlFunc({
          ...params,
        }),
        { replace: true },
      );
    }
  }, [params.before, params.after]);
  /* eslint-enable */
}
