import { TopNav } from "@dashboard/components/AppLayout/TopNav";
import CardMenu from "@dashboard/components/CardMenu";
import CardSpacer from "@dashboard/components/CardSpacer";
import { DateTime } from "@dashboard/components/Date";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import Savebar from "@dashboard/components/Savebar";
import Skeleton from "@dashboard/components/Skeleton";
import {
  ChannelUsabilityDataQuery,
  OrderLineInput,
  OrderValutaInput,
  SearchCustomersQuery,
} from "@dashboard/graphql";
import { SubmitPromise } from "@dashboard/hooks/useForm";
import useNavigator from "@dashboard/hooks/useNavigator";
import OrderChannelSectionCard from "@dashboard/orders/components/OrderChannelSectionCard";
import {
  OrderBothTypes,
  OrderErrorFragment,
  OrderSharedType,
} from "@dashboard/orders/types";
import { orderDraftListUrl } from "@dashboard/orders/urls";
import { FetchMoreProps, RelayToFlat } from "@dashboard/types";
import { Box as BoxUiCore, Typography } from "@material-ui/core";
import { ConfirmButtonTransitionState } from "@saleor/macaw-ui";
import { Box } from "@saleor/macaw-ui/next";
import { Voucher } from "@saleor/sdk/dist/apollo/types";
import React from "react";
import { useIntl } from "react-intl";

import OrderCustomer, { CustomerEditData } from "../OrderCustomer";
import OrderDraftDetails from "../OrderDraftDetails/OrderDraftDetails";
import OrderHistory, { FormData as HistoryFormData } from "../OrderHistory";
import OrderDraftAlert from "./OrderDraftAlert";
export interface OrderCustomer {
  user: string;
  userEmail: string;
  customerGroup: {
    usedGroupVoucher: boolean;
    voucher: Voucher;
  };
}
export interface OrderDraftPageProps extends FetchMoreProps {
  disabled: boolean;
  order?: OrderBothTypes;
  channelUsabilityData?: ChannelUsabilityDataQuery;
  users: RelayToFlat<SearchCustomersQuery["search"]>;
  usersLoading: boolean;
  errors: OrderErrorFragment[];
  saveButtonBarState: ConfirmButtonTransitionState;
  fetchUsers: (query: string) => void;
  onBillingAddressEdit: () => void;
  onCustomerEdit: (data: CustomerEditData) => any;
  onDraftFinalize: () => void;
  onDraftRemove: () => void;
  onNoteAdd: (data: HistoryFormData) => SubmitPromise<any[]>;
  onOrderLineAdd: () => void;
  onOrderLineChange: (id: string, data: OrderLineInput) => void;
  onOrderLineRemove: (id: string) => void;
  onProductClick: (id: string) => void;
  onShippingAddressEdit: () => void;
  onShippingMethodEdit: () => void;
  onProfileView: () => void;
  onOrderValutaEdit: (data: OrderValutaInput) => void;
  onPickupLocationEdit: () => void;
  pickupLocation?: any;
}

const OrderDraftPage: React.FC<OrderDraftPageProps> = props => {
  const {
    disabled,
    fetchUsers,
    hasMore,
    saveButtonBarState,
    onBillingAddressEdit,
    onCustomerEdit,
    onDraftFinalize,
    onDraftRemove,
    onFetchMore,
    onNoteAdd,
    onOrderLineAdd,
    onOrderLineChange,
    onOrderLineRemove,
    onShippingAddressEdit,
    onPickupLocationEdit,
    onShippingMethodEdit,
    onProfileView,
    order,
    channelUsabilityData,
    users,
    usersLoading,
    // onOrderValutaEdit,
    pickupLocation,
    errors,
  } = props;
  const navigate = useNavigator();
  const intl = useIntl();

  return (
    <BoxUiCore
      sx={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <BoxUiCore
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          flexBasis: { xs: "100%", md: "75%" },
        }}
      >
        <TopNav
          href={orderDraftListUrl()}
          title={
            <Box display="flex" alignItems="center" gap={6}>
              <span>{order?.number ? "#" + order?.number : undefined}</span>
              <div>
                {order && order.created ? (
                  <Typography variant="body2">
                    <DateTime date={order.created} plain />
                  </Typography>
                ) : (
                  <Skeleton style={{ width: "10em" }} />
                )}
              </div>
            </Box>
          }
        >
          <CardMenu
            menuItems={[
              {
                label: intl.formatMessage({
                  id: "PAqicb",
                  defaultMessage: "Cancel order",
                  description: "button",
                }),
                onSelect: onDraftRemove,
              },
            ]}
          />
        </TopNav>
        <OrderDraftAlert
          order={order as OrderSharedType}
          channelUsabilityData={channelUsabilityData}
        />
        <OrderDraftDetails
          order={order as OrderSharedType}
          customer={order?.user as any}
          channelUsabilityData={channelUsabilityData}
          errors={errors}
          // onOrderValutaEdit={onOrderValutaEdit as any}
          onOrderLineAdd={onOrderLineAdd}
          onOrderLineChange={onOrderLineChange}
          onOrderLineRemove={onOrderLineRemove}
          onShippingMethodEdit={onShippingMethodEdit}
        />
        <OrderHistory
          history={order?.events}
          orderCurrency={order?.total?.gross.currency}
          onNoteAdd={onNoteAdd}
        />
      </BoxUiCore>
      <BoxUiCore sx={{ width: "100%", flexBasis: { xs: "100%", md: "24%" } }}>
        <DetailPageLayout.RightSidebar>
          <OrderChannelSectionCard channel={order?.channel} />
          <CardSpacer />
          <OrderCustomer
            canEditAddresses={!!order?.user}
            canEditCustomer={true}
            pickupLocation={pickupLocation}
            fetchUsers={fetchUsers}
            hasMore={hasMore}
            loading={usersLoading}
            errors={errors}
            order={order as OrderSharedType}
            users={users}
            onBillingAddressEdit={onBillingAddressEdit}
            onCustomerEdit={onCustomerEdit}
            onFetchMore={onFetchMore}
            onProfileView={onProfileView}
            onPickupLocationEdit={onPickupLocationEdit}
            onShippingAddressEdit={onShippingAddressEdit}
          />
        </DetailPageLayout.RightSidebar>
        <Savebar
          state={saveButtonBarState}
          disabled={disabled}
          onCancel={() => navigate(orderDraftListUrl())}
          onSubmit={onDraftFinalize}
          labels={{
            confirm: intl.formatMessage({
              id: "4Z14xW",
              defaultMessage: "Finalize",
              description: "button",
            }),
          }}
        />
      </BoxUiCore>
    </BoxUiCore>
  );
};
OrderDraftPage.displayName = "OrderDraftPage";
export default OrderDraftPage;
