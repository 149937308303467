import { CustomerList } from "@dashboard/customers/views/CustomerList";
import { GroupCreateQueryParams } from "@dashboard/discounts/urls";
import { Card } from "@material-ui/core";
import React from "react";
interface GroupVoucherCustomersProps {
  customerEmails: string[];
  onChange: (emails: string[]) => void;
  params: GroupCreateQueryParams;
  value: string[];
  variant: "create" | "update";
  usingInGroupDetails?: boolean;
  selected?: any[];
}
export const GroupVoucherCustomers = ({
  params,
  onChange,
  selected = [],
  value,
  usingInGroupDetails = false,
}: GroupVoucherCustomersProps) => {
  const removeCustomerEmail = (email: string) => {
    onChange(value?.filter(customerEmail => customerEmail !== email));
  };
  const addCustomerEmail = (email: string) => {
    onChange([...value, email]);
  };
  return (
    <Card style={{ marginTop: "2em" }}>
      <CustomerList
        disableFiltering
        params={params}
        sortBySelected
        selected={selected}
        usingAsGroupCustomerList
        usingInGroupDetails={usingInGroupDetails}
        isSelectedCustom={(email: string) =>
          value?.some(customerEmail => customerEmail === email)
        }
        onSelectAllCustomers={(
          customers: any[],
          numOfSelectedCustomers: number,
          set,
        ) => {
          if (numOfSelectedCustomers === 0) {
            onChange(customers?.map(customer => customer.email));
            set(customers.map(customer => customer.id));
          } else {
            onChange([]);
            set([]);
          }
        }}
        onCustomerSelect={customer => {
          if (!customer?.email) {
            return;
          }
          const alreadyExists = value?.find(email => email === customer?.email);
          if (alreadyExists) {
            removeCustomerEmail(customer?.email);
          } else {
            addCustomerEmail(customer?.email);
          }
        }}
      />
    </Card>
  );
};

export default GroupVoucherCustomers;
