import { IFilter } from "@dashboard/components/Filter";
import { UseNavigatorResult } from "@dashboard/hooks/useNavigator";
import { ActiveTab, Pagination, Search, Sort } from "@dashboard/types";

import { GetFilterQueryParam, getFilterQueryParams } from "../filters";

type RequiredParams = ActiveTab & Search & Sort<any> & Pagination;
// type CreateUrl = (params: RequiredParams) => string;
type CreateFilterHandlers<TFilterKeys extends string> = [
  (filter: IFilter<TFilterKeys>) => void,
  () => void,
  (query: string) => void,
];

function createFilterHandlers<
  TFilterKeys extends string,
  TFilters extends {},
>(opts: {
  getFilterQueryParam: GetFilterQueryParam<TFilterKeys, TFilters>;
  navigate: UseNavigatorResult;
  createUrl: any; // CreateUrl
  params: RequiredParams;
  cleanupFn?: () => void;
}): CreateFilterHandlers<TFilterKeys> {
  const { getFilterQueryParam, navigate, createUrl, params, cleanupFn } = opts;

  const changeFilters = (filters: IFilter<TFilterKeys>) => {
    if (!!cleanupFn) {
      cleanupFn();
    }

    if ((params as any)?.id) {
      navigate(
        createUrl((params as any).id, {
          ...params,
          ...getFilterQueryParams(filters, getFilterQueryParam),
          activeTab: undefined,
        }),
      );
    } else {
      navigate(
        createUrl({
          ...params,
          ...getFilterQueryParams(filters, getFilterQueryParam),
          activeTab: undefined,
        }),
      );
    }
  };

  const resetFilters = () => {
    if (!!cleanupFn) {
      cleanupFn();
    }

    if ((params as any)?.id) {
      navigate(
        createUrl((params as any)?.id, {
          asc: params.asc,
          sort: params.sort,
        }),
      );
    } else {
      navigate(
        createUrl({
          asc: params.asc,
          sort: params.sort,
        }),
      );
    }
  };

  const handleSearchChange = (query: string) => {
    if (!!cleanupFn) {
      cleanupFn();
    }

    if (params.sort === "rank") {
      params.sort = "name";
    }

    if ((params as any)?.id) {
      navigate(
        createUrl((params as any).id, {
          ...params,
          after: undefined,
          before: undefined,
          activeTab: undefined,
          query: query?.trim(),
        }),
      );
    } else {
      navigate(
        createUrl({
          ...params,
          after: undefined,
          before: undefined,
          activeTab: undefined,
          query: query?.trim(),
        }),
      );
    }
  };

  return [changeFilters, resetFilters, handleSearchChange];
}

export default createFilterHandlers;
