import { TopNav } from "@dashboard/components/AppLayout";
import { Button } from "@dashboard/components/Button";
import FilterBar from "@dashboard/components/FilterBar/FilterBar";
import { ListPageLayout } from "@dashboard/components/Layouts";
import { groupAddUrl, GroupsUrlSortField } from "@dashboard/discounts/urls";
import { CustomerGroupFilterKeys } from "@dashboard/discounts/views/Groups/filters";
import { CustomerGroupsQuery } from "@dashboard/graphql";
import {
  ChannelProps,
  FilterPageProps,
  ListActions,
  PageListProps,
  SortPage,
  TabPageProps,
} from "@dashboard/types";
import { Card } from "@material-ui/core";
import React from "react";

import { GroupsList } from "../GroupsList/GroupsList";

export interface GroupPageProps
  extends PageListProps,
    ListActions,
    TabPageProps,
    FilterPageProps<CustomerGroupFilterKeys, any>,
    SortPage<GroupsUrlSortField>,
    TabPageProps,
    ChannelProps {
  groups: CustomerGroupsQuery["customerGroups"]["edges"];
  disableFiltering?: boolean;
}

export const GroupsPage: React.FC<GroupPageProps> = ({
  currentTab,
  initialSearch,
  onAll,
  onFilterChange,
  onSearchChange,
  onTabChange,
  onTabDelete,
  onTabSave,
  tabs,
  disableFiltering,
  ...listProps
}) => (
  <ListPageLayout>
    <TopNav title="Groups">
      <Button
        href={groupAddUrl()}
        variant="primary"
        data-test-id="create-group"
      >
        Create New Group
      </Button>
    </TopNav>
    <Card>
      <FilterBar
        disableFiltering={disableFiltering}
        allTabLabel={"All Groups"}
        currentTab={currentTab}
        filterStructure={null}
        initialSearch={initialSearch}
        searchPlaceholder={"Search group"}
        tabs={tabs}
        onAll={onAll}
        onFilterChange={onFilterChange}
        onSearchChange={onSearchChange}
        onTabChange={onTabChange}
        onTabDelete={onTabDelete}
        onTabSave={onTabSave}
      />
      <GroupsList {...listProps} filterDependency={null} />
    </Card>
  </ListPageLayout>
);

export default GroupsPage;
