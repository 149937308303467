import { FilterElement } from "@dashboard/components/Filter";

import {
  createFilterTabUtils,
  createFilterUtils,
} from "../../../utils/filters";
import {
  GroupsFIlterInput,
  GroupsQueryParams,
  GroupsUrlFilters,
  GroupsUrlFiltersEnum,
} from "../../urls";
export enum CustomerGroupFilterKeys {
  query = "query",
}
/* eslint-disable */
export const CUSTOMER_FILTERS_KEY = "customerFilters";

export function getFilterOpts(params: GroupsUrlFilters) {
  return {};
}

export function getFilterVariables(
  params: GroupsUrlFilters,
): GroupsFIlterInput {
  return {
    search: params.query,
  };
}

export function getFilterQueryParam(
  filter: FilterElement<CustomerGroupFilterKeys>,
): any {
  return {};
}

export const { deleteFilterTab, getFilterTabs, saveFilterTab } =
  createFilterTabUtils<GroupsUrlFilters>(CUSTOMER_FILTERS_KEY);

export const { areFiltersApplied, getActiveFilters, getFiltersCurrentTab } =
  createFilterUtils<GroupsQueryParams, GroupsUrlFilters>(GroupsUrlFiltersEnum);
/* eslint-enable */
