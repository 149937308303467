import {
  extensionMountPoints,
  mapToMenuItemsForCustomerOverviewActions,
  useExtensions,
} from "@dashboard/apps/hooks/useExtensions";
import { useUserPermissions } from "@dashboard/auth/hooks/useUserPermissions";
import { TopNav } from "@dashboard/components/AppLayout";
import CardMenu from "@dashboard/components/CardMenu/CardMenu";
import FilterBar from "@dashboard/components/FilterBar/FilterBar";
import { CustomerListUrlSortField } from "@dashboard/customers/urls";
import { BusinessRequestsQuery } from "@dashboard/graphql";
import {
  FilterPageProps,
  ListActions,
  PageListProps,
  RelayToFlat,
  SortPage,
  TabPageProps,
} from "@dashboard/types";
import { Card } from "@material-ui/core";
import { makeStyles } from "@saleor/macaw-ui";
import React from "react";
import { useIntl } from "react-intl";

import BusinessRequestsList from "../BusinessRequestsList/BusinessRequestsList";
import {
  createFilterStructure,
  CustomerFilterKeys,
  CustomerListFilterOpts,
} from "../CustomerListPage";

const useStyles = makeStyles(
  theme => ({
    settings: {
      marginRight: theme.spacing(2),
    },
  }),
  { name: "CustomerListPage" },
);

export interface BusinessRequestsListPageProps
  extends PageListProps,
    ListActions,
    FilterPageProps<CustomerFilterKeys, CustomerListFilterOpts>,
    SortPage<CustomerListUrlSortField>,
    TabPageProps {
  businessRequests: RelayToFlat<BusinessRequestsQuery["bussinesRequests"]>;
  selectedCustomerIds: string[];
  onCustomerSelect: (customer: any) => void;
  disableFiltering?: boolean;
}

export const BusinessRequestsListPage: React.FC<
  BusinessRequestsListPageProps
> = ({
  currentTab,
  filterOpts,
  initialSearch,
  onAll,
  onFilterChange,
  onSearchChange,
  onTabChange,
  onTabDelete,
  onTabSave,
  tabs,
  disableFiltering,
  selectedCustomerIds,
  ...businessRequestsListProps
}) => {
  const intl = useIntl();
  const classes = useStyles({});

  const userPermissions = useUserPermissions();
  const structure = createFilterStructure(intl, filterOpts, userPermissions);

  const { CUSTOMER_OVERVIEW_MORE_ACTIONS } = useExtensions(
    extensionMountPoints.CUSTOMER_LIST,
  );
  const extensionMenuItems = mapToMenuItemsForCustomerOverviewActions(
    CUSTOMER_OVERVIEW_MORE_ACTIONS,
    selectedCustomerIds,
  );

  return (
    <>
      <TopNav title={"Requests for business accounts"}>
        {extensionMenuItems.length > 0 && (
          <CardMenu
            className={classes.settings}
            menuItems={extensionMenuItems}
          />
        )}
      </TopNav>
      <Card>
        <FilterBar
          disableFiltering={disableFiltering}
          allTabLabel={intl.formatMessage({
            id: "6QoIfC",
            defaultMessage: "All Requestes",
            description: "tab name",
          })}
          currentTab={currentTab}
          filterStructure={structure}
          initialSearch={initialSearch}
          searchPlaceholder={intl.formatMessage({
            id: "2mRLis",
            defaultMessage: "Search Customer",
          })}
          tabs={tabs}
          onAll={onAll}
          onFilterChange={onFilterChange}
          onSearchChange={onSearchChange}
          onTabChange={onTabChange}
          onTabDelete={onTabDelete}
          onTabSave={onTabSave}
        />
        <BusinessRequestsList {...businessRequestsListProps} />
      </Card>
    </>
  );
};

export default BusinessRequestsListPage;
