import ActionDialog from "@dashboard/components/ActionDialog";
import DeleteFilterTabDialog from "@dashboard/components/DeleteFilterTabDialog";
import SaveFilterTabDialog, {
  SaveFilterTabDialogFormData,
} from "@dashboard/components/SaveFilterTabDialog";
import { WindowTitle } from "@dashboard/components/WindowTitle";
import { groupAddUrl, groupDetailsUrl } from "@dashboard/discounts/urls";
import {
  useBulkRemoveCustomersMutation,
  useListCustomersQuery,
} from "@dashboard/graphql";
import useBulkActions from "@dashboard/hooks/useBulkActions";
import useListSettings from "@dashboard/hooks/useListSettings";
import useNavigator from "@dashboard/hooks/useNavigator";
import useNotifier from "@dashboard/hooks/useNotifier";
import { usePaginationReset } from "@dashboard/hooks/usePaginationReset";
import usePaginator, {
  createPaginationState,
  PaginatorContext,
} from "@dashboard/hooks/usePaginator";
import { commonMessages, sectionNames } from "@dashboard/intl";
import { maybe } from "@dashboard/misc";
import { ListViews } from "@dashboard/types";
import createDialogActionHandlers from "@dashboard/utils/handlers/dialogActionHandlers";
import createFilterHandlers from "@dashboard/utils/handlers/filterHandlers";
import createSortHandler from "@dashboard/utils/handlers/sortHandler";
import { mapEdgesToItems } from "@dashboard/utils/maps";
import { getSortParams } from "@dashboard/utils/sort";
import { DialogContentText } from "@material-ui/core";
import { DeleteIcon, IconButton } from "@saleor/macaw-ui";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import useRouter from "use-react-router";

import CustomerListPage from "../../components/CustomerListPage";
import {
  customerListUrl,
  CustomerListUrlDialog,
  CustomerListUrlQueryParams,
} from "../../urls";
import {
  deleteFilterTab,
  getActiveFilters,
  getFilterOpts,
  getFilterQueryParam,
  getFiltersCurrentTab,
  getFilterTabs,
  getFilterVariables,
  saveFilterTab,
} from "./filters";
import { getSortQueryVariables } from "./sort";

interface CustomerListProps {
  params: CustomerListUrlQueryParams;
  usingAsGroupCustomerList?: boolean;
  usingInGroupDetails?: boolean;
  onCustomerSelect?: (customer: any) => void;
  onSelectAllCustomers: (
    customers: any[],
    selected: number,
    set: (data: string[]) => void,
  ) => void;
  isSelectedCustom?: (email: string) => {};
  disableFiltering?: boolean;
  sortBySelected?: boolean;
  selected?: any[];
}

export const CustomerList: React.FC<CustomerListProps> = ({
  params,
  usingAsGroupCustomerList = false,
  usingInGroupDetails = false,
  disableFiltering = false,
  selected = [],
  sortBySelected = false,
  onCustomerSelect = () => {}, // eslint-disable-line
  onSelectAllCustomers = () => {}, // eslint-disable-line
  isSelectedCustom = null,
}) => {
  const router = useRouter();
  const navigate = useNavigator();
  const notify = useNotifier();
  const { isSelected, listElements, reset, toggle, toggleAll, set } =
    useBulkActions(params.ids);

  const { updateListSettings, settings } = useListSettings(
    ListViews.CUSTOMER_LIST,
  );

  usePaginationReset(
    usingAsGroupCustomerList
      ? usingInGroupDetails
        ? groupDetailsUrl
        : (groupAddUrl as any)
      : customerListUrl,
    params,
    settings.rowNumber,
  );

  const intl = useIntl();

  const paginationState = createPaginationState(settings.rowNumber, params);
  const queryVariables = React.useMemo(
    () => ({
      ...paginationState,
      filter: getFilterVariables(params),
      sort: getSortQueryVariables(params),
    }),
    [params, settings.rowNumber], // eslint-disable-line
  );
  const { data, loading, refetch } = useListCustomersQuery({
    displayLoader: true,
    variables: queryVariables,
  });

  const tabs = getFilterTabs();

  const currentTab = getFiltersCurrentTab(params, tabs);

  const [changeFilters, resetFilters, handleSearchChange] =
    createFilterHandlers({
      cleanupFn: reset,
      createUrl: usingAsGroupCustomerList
        ? usingInGroupDetails
          ? (groupDetailsUrl as any)
          : (groupAddUrl as any)
        : customerListUrl,
      getFilterQueryParam,
      navigate,
      params,
    });

  const [openModal, closeModal] = createDialogActionHandlers<
    CustomerListUrlDialog,
    CustomerListUrlQueryParams
  >(
    navigate,
    usingAsGroupCustomerList
      ? usingInGroupDetails
        ? groupDetailsUrl
        : (groupAddUrl as any)
      : customerListUrl,
    params,
  );

  const handleTabChange = (tab: number) => {
    reset();
    navigate(
      usingAsGroupCustomerList
        ? usingInGroupDetails
          ? groupDetailsUrl((router?.match?.params as any)?.id, {
              activeTab: tab.toString(),
              ...getFilterTabs()[tab - 1].data,
            })
          : groupAddUrl({
              activeTab: tab.toString(),
              ...getFilterTabs()[tab - 1].data,
            })
        : customerListUrl({
            activeTab: tab.toString(),
            ...getFilterTabs()[tab - 1].data,
          }),
    );
  };

  const handleTabDelete = () => {
    deleteFilterTab(currentTab);
    reset();
    navigate(
      usingAsGroupCustomerList
        ? usingInGroupDetails
          ? groupDetailsUrl((router?.match?.params as any)?.id)
          : groupAddUrl()
        : customerListUrl(),
    );
  };

  const handleTabSave = (data: SaveFilterTabDialogFormData) => {
    saveFilterTab(data.name, getActiveFilters(params));
    handleTabChange(tabs.length + 1);
  };

  const paginationValues = usePaginator({
    pageInfo: maybe(() => data.customers.pageInfo),
    paginationState,
    queryString: params,
  });

  const [bulkRemoveCustomers, bulkRemoveCustomersOpts] =
    useBulkRemoveCustomersMutation({
      onCompleted: data => {
        if (data.customerBulkDelete.errors.length === 0) {
          notify({
            status: "success",
            text: intl.formatMessage(commonMessages.savedChanges),
          });
          reset();
          refetch();
          closeModal();
        }
      },
    });

  const handleSort = createSortHandler(
    navigate,
    usingAsGroupCustomerList
      ? usingInGroupDetails
        ? (groupDetailsUrl as any)
        : (groupAddUrl as any)
      : customerListUrl,
    params,
  );

  return (
    <PaginatorContext.Provider value={paginationValues}>
      <WindowTitle title={intl.formatMessage(sectionNames.customers)} />
      <CustomerListPage
        disableFiltering={disableFiltering}
        currentTab={currentTab}
        filterOpts={getFilterOpts(params)}
        initialSearch={params.query || ""}
        onSearchChange={handleSearchChange}
        onFilterChange={changeFilters}
        onAll={resetFilters}
        onTabChange={handleTabChange}
        onTabDelete={() => openModal("delete-search")}
        onTabSave={() => openModal("save-search")}
        tabs={tabs.map(tab => tab.name)}
        customers={
          mapEdgesToItems(data?.customers)?.sort(a =>
            sortBySelected ? (selected?.includes(a.email) ? -1 : 1) : -1,
          ) as any[]
        }
        settings={settings}
        disabled={loading}
        onUpdateListSettings={updateListSettings}
        onSort={handleSort}
        onCustomerSelect={onCustomerSelect}
        toolbar={
          <IconButton
            variant="secondary"
            color="primary"
            onClick={() =>
              openModal("remove", {
                ids: listElements,
              })
            }
          >
            <DeleteIcon />
          </IconButton>
        }
        isChecked={(customer: any): any => {
          if (isSelectedCustom) {
            return isSelectedCustom(customer?.email);
          } else {
            return isSelected(customer?.id);
          }
        }}
        selected={listElements.length}
        selectedCustomerIds={listElements}
        sort={getSortParams(params)}
        toggle={toggle}
        toggleAll={(customers: any[], selected) => {
          onSelectAllCustomers(customers, selected, set);
          return toggleAll(customers, selected);
        }}
      />
      <ActionDialog
        open={params.action === "remove" && maybe(() => params.ids.length > 0)}
        onClose={closeModal}
        confirmButtonState={bulkRemoveCustomersOpts.status}
        onConfirm={() =>
          bulkRemoveCustomers({
            variables: {
              ids: params.ids,
            },
          })
        }
        variant="delete"
        title={intl.formatMessage({
          id: "q8ep2I",
          defaultMessage: "Delete Customers",
          description: "dialog header",
        })}
      >
        <DialogContentText>
          <FormattedMessage
            id="N2SbNc"
            defaultMessage="{counter,plural,one{Are you sure you want to delete this customer?} other{Are you sure you want to delete {displayQuantity} customers?}}"
            values={{
              counter: maybe(() => params.ids.length),
              displayQuantity: (
                <strong>{maybe(() => params.ids.length)}</strong>
              ),
            }}
          />
        </DialogContentText>
      </ActionDialog>
      <SaveFilterTabDialog
        open={params.action === "save-search"}
        confirmButtonState="default"
        onClose={closeModal}
        onSubmit={handleTabSave}
      />
      <DeleteFilterTabDialog
        open={params.action === "delete-search"}
        confirmButtonState="default"
        onClose={closeModal}
        onSubmit={handleTabDelete}
        tabName={maybe(() => tabs[currentTab - 1].name, "...")}
      />
    </PaginatorContext.Provider>
  );
};
export default CustomerList;
