import { useWarehousesListQuery } from "@dashboard/graphql";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import React from "react";
interface WarehousesListProps {
  selectedWarehouse: any;
  onChange: (warehouseId: any) => void;
}
export const WarehousesList = ({
  selectedWarehouse,
  onChange,
}: WarehousesListProps) => {
  const { data } = useWarehousesListQuery();

  return (
    <div>
      {data?.warehouses?.edges?.length > 0 ? (
        <RadioGroup
          className=""
          value={selectedWarehouse?.id}
          onChange={e =>
            onChange(
              data.warehouses.edges.find(
                (v: any) => v.node.id === e.target.value,
              )?.node,
            )
          }
        >
          {data.warehouses.edges.map(warehouse => (
            <FormControlLabel
              label={warehouse.node.name}
              value={warehouse.node.id}
              control={<Radio color="primary" />}
            />
          ))}
        </RadioGroup>
      ) : (
        <p>No available warehouses locations</p>
      )}
    </div>
  );
};

export default WarehousesList;
