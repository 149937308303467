import DeleteFilterTabDialog from "@dashboard/components/DeleteFilterTabDialog/DeleteFilterTabDialog";
import SaveFilterTabDialog from "@dashboard/components/SaveFilterTabDialog/SaveFilterTabDialog";
import { WindowTitle } from "@dashboard/components/WindowTitle";
import { GroupsPage } from "@dashboard/discounts/components/GroupsPage";
import {
  GroupsQueryParams,
  groupsUrl,
  GroupsUrlDialog,
} from "@dashboard/discounts/urls";
import { useCustomerGroupsQuery } from "@dashboard/graphql";
import useBulkActions from "@dashboard/hooks/useBulkActions";
import useListSettings from "@dashboard/hooks/useListSettings";
import useNavigator from "@dashboard/hooks/useNavigator";
// import { getSortQueryVariables } from "./sort";
import { usePaginationReset } from "@dashboard/hooks/usePaginationReset";
import usePaginator, {
  createPaginationState,
  PaginatorContext,
} from "@dashboard/hooks/usePaginator";
import { maybe } from "@dashboard/misc";
import { ListViews } from "@dashboard/types";
import createDialogActionHandlers from "@dashboard/utils/handlers/dialogActionHandlers";
import createFilterHandlers from "@dashboard/utils/handlers/filterHandlers";
import createSortHandler from "@dashboard/utils/handlers/sortHandler";
import { getSortParams } from "@dashboard/utils/sort";
import React from "react";

import {
  deleteFilterTab,
  getActiveFilters,
  getFilterQueryParam,
  // getFilterVariables,
  getFiltersCurrentTab,
  getFilterTabs,
  getFilterVariables,
  saveFilterTab,
} from "./filters";
import { getSortQueryVariables } from "./sort";

// import { PaginatorContext } from "@dashboard/hooks/usePaginator";
interface GroupsProps {
  params: GroupsQueryParams;
}
export const Groups = ({ params }: GroupsProps) => {
  const navigate = useNavigator();
  const { updateListSettings, settings } = useListSettings(
    ListViews.CUSTOMER_LIST,
  );
  const { reset } = useBulkActions(params.ids);

  usePaginationReset(groupsUrl, params, settings.rowNumber);

  const paginationState = createPaginationState(settings.rowNumber, params);
  const queryVariables = React.useMemo(
    () => ({
      ...paginationState,
      filter: getFilterVariables(params),
      sort: getSortQueryVariables(params),
    }),
    [params, settings?.rowNumber],
  );

  const { data: groups, loading } = useCustomerGroupsQuery({
    displayLoader: true,
    variables: queryVariables,
    // variables: queryVariables,
  });

  const paginationValues = usePaginator({
    pageInfo: maybe(() => (groups?.customerGroups as any)?.pageInfo || {}),
    paginationState,
    queryString: params,
  });

  const [openModal, closeModal] = createDialogActionHandlers<
    GroupsUrlDialog,
    GroupsQueryParams
  >(navigate, groupsUrl, params);

  const tabs = getFilterTabs();

  const currentTab = getFiltersCurrentTab(params, tabs);

  const [changeFilters, resetFilters, handleSearchChange] =
    createFilterHandlers({
      cleanupFn: reset,
      createUrl: groupsUrl,
      getFilterQueryParam,
      navigate,
      params,
    });

  const handleTabChange = (tab: number) => {
    reset();
    navigate(
      groupsUrl({
        activeTab: tab.toString(),
        ...getFilterTabs()[tab - 1].data,
      }),
    );
  };

  const handleTabDelete = () => {
    deleteFilterTab(currentTab);
    reset();
    navigate(groupsUrl());
  };

  const handleTabSave = (data: any) => {
    saveFilterTab(data.name, getActiveFilters(params));
    handleTabChange(tabs.length + 1);
  };

  // <PaginatorContext.Provider value={{} as any}></PaginatorContext.Provider>
  const handleSort = createSortHandler(navigate, groupsUrl as any, params);
  return (
    <PaginatorContext.Provider value={paginationValues}>
      <WindowTitle title="Groups" />
      <GroupsPage
        disableFiltering={true}
        tabs={tabs.map(tab => tab.name)}
        /** Promijeniti kad damjan doda filtere */
        isChecked={() => false}
        toggle={() => {}}
        toggleAll={() => {}}
        onAll={resetFilters}
        onTabDelete={() => openModal("delete-search")}
        onTabSave={() => openModal("save-search")}
        onTabChange={handleTabChange}
        selected={0}
        toolbar={null}
        filterOpts={null}
        initialSearch={params.query || ""}
        onSearchChange={handleSearchChange}
        onFilterChange={changeFilters}
        selectedChannelId=""
        onListSettingsReset={() => {}}
        /** ----- */
        groups={groups?.customerGroups?.edges}
        currentTab={currentTab}
        disabled={loading}
        onSort={handleSort}
        sort={getSortParams(params)}
        settings={settings}
        onUpdateListSettings={updateListSettings}
      />
      <SaveFilterTabDialog
        open={params.action === "save-search"}
        confirmButtonState="default"
        onClose={closeModal}
        onSubmit={handleTabSave}
      />
      <DeleteFilterTabDialog
        open={params.action === "delete-search"}
        confirmButtonState="default"
        onClose={closeModal}
        onSubmit={handleTabDelete}
        tabName={maybe(() => tabs[currentTab - 1].name, "...")}
      />
    </PaginatorContext.Provider>
  );
};

export default Groups;
