// Graphql
import { useOrderComplaintListQuery } from "@dashboard/graphql";
import useListSettings from "@dashboard/hooks/useListSettings";
import useNavigator from "@dashboard/hooks/useNavigator";
import { usePaginationReset } from "@dashboard/hooks/usePaginationReset";
import usePaginator, {
  createPaginationState,
  PaginatorContext,
} from "@dashboard/hooks/usePaginator";
import { useSortRedirects } from "@dashboard/hooks/useSortRedirects";
import {
  OrderComplaintListQueryParams,
  orderComplaintListUrl,
  OrderComplaintListUrlSortField,
} from "@dashboard/orders/urls";
import { ListViews } from "@dashboard/types";
import createSortHandler from "@dashboard/utils/handlers/sortHandler";
// Other
import { getSortParams } from "@dashboard/utils/sort";
import React, { memo } from "react";

// Components
import OrderComplaintListPage from "../../components/OrderComplaintListPage";
import { DEFAULT_SORT_KEY, getSortQueryVariables } from "./sort";
interface OrderComplaintListProps {
  params: OrderComplaintListQueryParams;
}

export const OrderComplaintList = ({ params }: OrderComplaintListProps) => {
  const navigate = useNavigator();
  const { updateListSettings, settings } = useListSettings(
    ListViews.ORDER_LIST,
  );
  usePaginationReset(orderComplaintListUrl, params, settings.rowNumber);
  const paginationState = createPaginationState(settings.rowNumber, params);

  const queryVariables = React.useMemo(
    () => ({
      ...paginationState,
      sort: getSortQueryVariables(params),
    }),
    [params, settings.rowNumber], // eslint-disable-line
  );
  const { data, loading } = useOrderComplaintListQuery({
    displayLoader: true,
    variables: queryVariables,
  });

  useSortRedirects<OrderComplaintListUrlSortField>({
    params,
    defaultSortField: DEFAULT_SORT_KEY,
    urlFunc: orderComplaintListUrl,
  });

  const paginationValues = usePaginator({
    pageInfo: data?.returnables?.pageInfo,
    paginationState,
    queryString: params,
  });

  const handleSort = createSortHandler(navigate, orderComplaintListUrl, params);

  return (
    <div>
      <PaginatorContext.Provider value={paginationValues}>
        <OrderComplaintListPage
          settings={settings}
          onUpdateListSettings={updateListSettings}
          orders={data?.returnables?.edges}
          onSort={handleSort}
          disabled={loading}
          sort={getSortParams(params)}
        />
      </PaginatorContext.Provider>
    </div>
  );
};
/* eslint-disable */
export default memo(OrderComplaintList);
/* eslint-enable */
