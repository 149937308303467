import { ChannelVoucherData } from "@dashboard/channels/utils";
import { TopNav } from "@dashboard/components/AppLayout";
import Form from "@dashboard/components/Form/Form";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import { RevealContent } from "@dashboard/components/RevealContent";
import Savebar from "@dashboard/components/Savebar";
import {
  groupAddUrl,
  GroupCreateQueryParams,
  groupDetailsUrl,
  groupsUrl,
} from "@dashboard/discounts/urls";
import { CHANNELS } from "@dashboard/discounts/utils";
import { DiscountValueTypeEnum } from "@dashboard/graphql";
import { SubmitPromise } from "@dashboard/hooks/useForm";
import useNavigator from "@dashboard/hooks/useNavigator";
import React, { useState } from "react";

import GroupCategories from "../GroupCategories/GroupCategories";
import { GroupPageInfo } from "../GroupPageInfo/GroupPageInfo";
import { GroupVoucherCustomers } from "../GroupVoucherCustomers";

export interface FormData {
  name: string;
  customerEmails: string[];
  discountedCategories: Array<{
    id: string;
    discountOptions: { value: string; valueType: DiscountValueTypeEnum };
  }>;
  channel: string;
  /**  discountedProducts: Array<{
    id: string;
    discountOptions: { value: string; valueType: DiscountValueTypeEnum };
  }>; */
  // discountedProducts: {};
  limit: number;
}
interface GroupCreatePageProps {
  onSubmit: (data: FormData) => SubmitPromise;
  channelListings: ChannelVoucherData[];
  params: GroupCreateQueryParams;
  variant?: "update" | "create";
  title?: string;
  initialFormData?: FormData;
  onDelete?: () => void;
  errors: any[];
  groupCustomers?: any[];
  listOfVouchers: any[];
}
export const GroupCreatePage = ({
  onSubmit,
  params,
  variant = "create",
  onDelete,
  title,
  initialFormData,
  groupCustomers = [],
  errors = [],
}: GroupCreatePageProps) => {
  const navigate = useNavigator();
  const [hide, setHide] = useState<any>({
    categories: true,
    customers: true,
    products: true,
  });
  const initialForm: FormData = initialFormData
    ? initialFormData
    : {
        name: "",
        limit: 0,
        customerEmails: [],
        discountedCategories: [],
        channel: CHANNELS[1]?.id, // default-channel
      };

  const deleteQueries = () => {
    if (Object.keys(params).length === 0) {
      return;
    }

    navigate(
      variant === "create"
        ? groupAddUrl({})
        : groupDetailsUrl((params as any)?.id),
    );
  };

  return (
    <Form confirmLeave initial={initialForm} onSubmit={onSubmit}>
      {({ change, data, submit }) => (
        <DetailPageLayout>
          <TopNav href={groupsUrl()} title={title ? title : "Create Group"} />
          {/** content */}
          <DetailPageLayout.Content>
            {/** Group Info */}
            <GroupPageInfo
              onChange={change}
              data={data}
              errors={errors}
              variant={variant}
              disabled={variant === "update"}
            />
            {/** Voucher for group  */}
            {/** <RevealContent title="Voucher">
              <VoucherGroup
                data={data}
                change={change}
                variant={variant}
                listOfVouchers={listOfVouchers}
                errors={errors}
                disabled={variant === "update"}
              />
            </RevealContent> */}
            {/** Categories we want to add discount to */}

            <RevealContent
              title="Categories"
              forceOpenedStatus={!hide.categories}
              onClick={() => {
                deleteQueries();
                setHide({
                  customers: true,
                  products: true,
                  categories: false,
                });
              }}
            >
              {hide.categories ? null : (
                <GroupCategories
                  discountedCategories={data?.discountedCategories}
                  onChange={change}
                />
              )}
            </RevealContent>

            {/** <RevealContent
              title="Products"
              forceOpenedStatus={!hide.products}
              onClick={() => {
                deleteQueries();
                setHide({
                  products: false,
                  customers: true,
                  categories: true,
                });
              }}
            >
              <GroupProducts
                discountedProducts={data?.discountedProducts}
                onChange={change}
              />
            </RevealContent> */}

            {/** Customers for group */}
            <RevealContent
              title="Customers"
              forceOpenedStatus={!hide.customers}
              onClick={() => {
                deleteQueries();
                setHide({
                  customers: false,
                  products: true,
                  categories: true,
                });
              }}
            >
              {hide.customers ? null : (
                <GroupVoucherCustomers
                  selected={groupCustomers}
                  variant={variant as any}
                  usingInGroupDetails={variant === "update"}
                  params={params}
                  customerEmails={data?.customerEmails}
                  value={data.customerEmails}
                  onChange={(emails: string[]) => {
                    change({
                      target: {
                        name: "customerEmails",
                        value: emails,
                      },
                    });
                  }}
                />
              )}
            </RevealContent>
          </DetailPageLayout.Content>
          <Savebar
            disabled={false}
            onCancel={() => navigate(groupsUrl())}
            onSubmit={submit}
            onDelete={onDelete}
            state={{} as any}
          />
        </DetailPageLayout>
      )}
    </Form>
  );
};

export default GroupCreatePage;
