import { Button } from "@dashboard/components/Button";
import CardTitle from "@dashboard/components/CardTitle";
import { ChannelsAvailabilityDropdown } from "@dashboard/components/ChannelsAvailabilityDropdown";
import Checkbox from "@dashboard/components/Checkbox";
import ResponsiveTable from "@dashboard/components/ResponsiveTable";
import Skeleton from "@dashboard/components/Skeleton";
import { TableButtonWrapper } from "@dashboard/components/TableButtonWrapper/TableButtonWrapper";
import TableCellAvatar from "@dashboard/components/TableCellAvatar";
import TableHead from "@dashboard/components/TableHead";
import { TablePaginationWithContext } from "@dashboard/components/TablePagination";
import TableRowLink from "@dashboard/components/TableRowLink";
import {
  SaleDetailsFragment,
  useCollectionAssignProductMutation,
  useSaleCataloguesAddMutation,
  useSaleCataloguesRemoveMutation,
  useUnassignCollectionProductMutation,
  VoucherDetailsFragment,
} from "@dashboard/graphql";
import useNotifier from "@dashboard/hooks/useNotifier/useNotifier";
import { Card, TableBody, TableCell, TableFooter } from "@material-ui/core";
import { DeleteIcon, IconButton } from "@saleor/macaw-ui";
import React from "react";
import { AiOutlinePercentage } from "react-icons/ai";
import { FormattedMessage } from "react-intl";

import { maybe, renderCollection } from "../../../misc";
import { ListActions, ListProps, RelayToFlat } from "../../../types";
import { messages } from "./messages";
import { useStyles } from "./styles";
import { VELIKA_SEZONSKA_AKCIJA_ID } from "./utils";

export interface SaleProductsProps extends ListProps, ListActions {
  products:
    | RelayToFlat<SaleDetailsFragment["products"]>
    | RelayToFlat<VoucherDetailsFragment["products"]>;
  title?: string;
  onProductAssign: () => void;
  onProductUnassign: (id: string) => void;
}

const numberOfColumns = 5;

const DiscountProducts: React.FC<SaleProductsProps> = props => {
  const {
    products,
    disabled,
    onProductAssign,
    onProductUnassign,
    isChecked,
    selected,
    toggle,
    toggleAll,
    toolbar,
    title,
  } = { ...props };
  const [assignProductToCollection] = useCollectionAssignProductMutation({
    onCompleted: data => {
      if (data.collectionAddProducts.errors.length > 0) {
        notify({
          status: "error",
          text: data.collectionAddProducts.errors[0]?.message,
        });
        return;
      }
    },
  });
  const [addSaleCatalogues] = useSaleCataloguesAddMutation({
    onCompleted: data => {
      if (data?.saleCataloguesAdd?.errors?.length > 0) {
        notify({
          status: "error",
          text: data.saleCataloguesAdd.errors[0]?.message,
        });
        return;
      }
      // successfully....
      notify({
        status: "success",
        text: `Product is successfully added in collection`,
      });
    },
  });

  const [unassignProductCollection] = useUnassignCollectionProductMutation();
  const [unassignProductSale] = useSaleCataloguesRemoveMutation();

  const classes = useStyles(props);
  const notify = useNotifier();

  return (
    <Card>
      <CardTitle
        title={title}
        toolbar={
          <Button onClick={onProductAssign} data-test-id="assign-products">
            <FormattedMessage {...messages.discountProductsButton} />
          </Button>
        }
      />
      <ResponsiveTable>
        <colgroup>
          <col />
          <col className={classes.colName} />
          <col className={classes.colType} />
          <col className={classes.colPublished} />
          <col className={classes.colActions} />
        </colgroup>
        <TableHead
          colSpan={numberOfColumns}
          selected={selected}
          disabled={disabled}
          items={products}
          toggleAll={toggleAll}
          toolbar={toolbar}
        >
          <TableCell className={classes.colName}>
            <span className={products?.length > 0 && classes.colNameLabel}>
              <FormattedMessage
                {...messages.discountProductsTableProductHeader}
              />
            </span>
          </TableCell>
          <TableCell className={classes.colType}>
            <FormattedMessage {...messages.discountProductsTableTypeHeader} />
          </TableCell>
          <TableCell className={classes.colPublished}>
            <FormattedMessage
              {...messages.discountProductsTableAvailabilityHeader}
            />
          </TableCell>
          <TableCell className={classes.colActions} />
        </TableHead>
        <TableFooter>
          <TableRowLink>
            <TablePaginationWithContext colSpan={numberOfColumns} />
          </TableRowLink>
        </TableFooter>
        <TableBody>
          {renderCollection(
            products,
            product => {
              const isSelected = product ? isChecked(product.id) : false;

              return (
                <TableRowLink
                  hover={!!product}
                  key={product ? product.id : "skeleton"}
                  className={classes.tableRow}
                  selected={isSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isSelected}
                      disabled={disabled}
                      disableClickPropagation
                      onChange={() => toggle(product.id)}
                    />
                  </TableCell>
                  <TableCellAvatar
                    className={classes.colName}
                    thumbnail={maybe(() => product.thumbnail.url)}
                  >
                    {maybe<React.ReactNode>(() => product.name, <Skeleton />)}
                  </TableCellAvatar>
                  <TableCell className={classes.colType}>
                    {maybe<React.ReactNode>(
                      () => product.productType.name,
                      <Skeleton />,
                    )}
                  </TableCell>
                  <TableCell className={classes.colType}>
                    {product && !product?.channelListings?.length ? (
                      "-"
                    ) : product?.channelListings !== undefined ? (
                      <ChannelsAvailabilityDropdown
                        channels={product?.channelListings}
                      />
                    ) : (
                      <Skeleton />
                    )}
                  </TableCell>

                  <TableCell
                    className={classes.colActions}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      maxWidth: "100% !important",
                      width: "fit-content !important",
                    }}
                  >
                    <TableButtonWrapper>
                      <>
                        <IconButton
                          variant="secondary"
                          title='Assign product to "Velika sezonska akcija"'
                          disabled={!product || disabled}
                          style={{ marginRight: "5px" }}
                          onClick={async event => {
                            event.stopPropagation();
                            /* eslint-disable */
                            await assignProductToCollection({
                              variables: {
                                productIds: [product.id],
                                collectionId: VELIKA_SEZONSKA_AKCIJA_ID,
                                first: 30,
                              },
                            });
                            await addSaleCatalogues({
                              variables: {
                                includeProducts: true,
                                includeCategories: false,
                                includeCollections: false,
                                includeVariants: false,
                                first: 1,
                                id: "U2FsZTox",
                                input: {
                                  products: [product?.id],
                                },
                              },
                            });
                          }}
                        >
                          <AiOutlinePercentage />
                        </IconButton>
                        <IconButton
                          variant="secondary"
                          disabled={!product || disabled}
                          onClick={event => {
                            event.stopPropagation();
                            onProductUnassign(product.id);
                            unassignProductCollection({
                              variables: {
                                productIds: [product.id],
                                collectionId: VELIKA_SEZONSKA_AKCIJA_ID,
                                first: 30,
                              },
                            });
                            unassignProductSale({
                              variables: {
                                includeProducts: true,
                                includeCategories: false,
                                includeCollections: false,
                                includeVariants: false,
                                first: 1,
                                id: "U2FsZTox",
                                input: {
                                  products: [product?.id],
                                },
                              },
                            });
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    </TableButtonWrapper>
                  </TableCell>
                </TableRowLink>
              );
            },
            () => (
              <TableRowLink>
                <TableCell colSpan={numberOfColumns}>
                  <FormattedMessage {...messages.discountProductsNotFound} />
                </TableCell>
              </TableRowLink>
            ),
          )}
        </TableBody>
      </ResponsiveTable>
    </Card>
  );
};
DiscountProducts.displayName = "DiscountProducts";
export default DiscountProducts;
