import CardTitle from "@dashboard/components/CardTitle/CardTitle";
import { DateTime } from "@dashboard/components/Date";
import ResponsiveTable from "@dashboard/components/ResponsiveTable";
import Skeleton from "@dashboard/components/Skeleton";
import TableRowLink from "@dashboard/components/TableRowLink";
import { getApiUrl } from "@dashboard/config";
import { CustomerDetailsQuery } from "@dashboard/graphql";
import useNavigator from "@dashboard/hooks/useNavigator";
import { maybe, renderCollection } from "@dashboard/misc";
import { productUrl } from "@dashboard/products/urls";
import {
  Button,
  Card,
  TableBody,
  TableCell,
  TableHead,
} from "@material-ui/core";
import { makeStyles } from "@saleor/macaw-ui";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles(
  {
    link: {
      cursor: "pointer",
    },
    textRight: {
      textAlign: "right",
    },
  },
  { name: "CustomerOrders" },
);

interface CustomerSeenProductsProps {
  seenProducts: CustomerDetailsQuery["user"]["seenProducts"];
}

export const CustomerSeenProducts = ({
  seenProducts,
}: CustomerSeenProductsProps) => {
  const navigate = useNavigator();
  const [customerSeenProducts, setCustomerSeenProducts] = useState<
    CustomerDetailsQuery["user"]["seenProducts"]
  >([]);

  useEffect(() => {
    setCustomerSeenProducts(seenProducts);
  }, [seenProducts]);

  const classes = useStyles();
  return (
    <Card>
      <CardTitle
        title="Last seen products"
        toolbar={
          <Button
            title="Sort"
            onClick={() => {
              setCustomerSeenProducts(
                [...seenProducts].sort((a, b) =>
                  +a.timesSeen > +b.timesSeen ? -1 : 1,
                ),
              );
            }}
          >
            Sort
          </Button>
        }
      />
      <ResponsiveTable>
        {/** HEAD */}
        <TableHead>
          <TableRowLink>
            {/** PRODUCT NAME */}
            <TableCell>Product Name</TableCell>

            {/** TIMES SEEN */}
            <TableCell>Times seen</TableCell>
            {/** LAST SEEN */}
            <TableCell>Last Seen</TableCell>
            {/** CHANNEL      */}
            <TableCell className={classes.textRight}>Channel</TableCell>
          </TableRowLink>
        </TableHead>
        {/** BODY */}
        <TableBody>
          {renderCollection(customerSeenProducts, seenProduct => (
            <TableRowLink
              hover={!!seenProduct}
              key={seenProduct.name}
              onClick={async () => {
                if (!seenProduct.productSlug) {
                  return;
                }
                const response = await fetch(getApiUrl(), {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    "stellate-token":
                      "3e0d305e54c33c1010261b67ff89ccca66f1497b28f722cee6a3e19f59edfcc7",
                  },
                  body: JSON.stringify({
                    query: ` query ProductBySlug($slug: String!, $channel:String!) {
                      product(slug: $slug, channel: $channel) {
                        id
                      }
                    }`,
                    variables: {
                      slug: seenProduct.productSlug,
                      channel: seenProduct.onChannel || "default-channel",
                    },
                  }),
                });
                const data = await response.json();

                if (!data?.data?.product?.id) {
                  return;
                }
                navigate(productUrl(data?.data?.product?.id));
              }}
              className={!!seenProduct ? classes.link : undefined}
            >
              {/** PRODUCT NAME */}
              <TableCell>
                {maybe(() => seenProduct.name) ? (
                  seenProduct.name
                ) : (
                  <Skeleton />
                )}
              </TableCell>
              {/** TIMES SEEN */}
              <TableCell>
                {maybe(() => seenProduct.timesSeen) ? (
                  seenProduct.timesSeen
                ) : (
                  <Skeleton />
                )}
              </TableCell>
              {/** LAST SEEN */}
              <TableCell>
                {maybe(() => seenProduct.latestDateSeen) ? (
                  <DateTime date={seenProduct?.latestDateSeen} plain />
                ) : (
                  <Skeleton />
                )}
              </TableCell>
              {/** CHANNEL */}
              <TableCell className={classes.textRight}>
                {maybe(() => seenProduct.onChannel) ? (
                  seenProduct.onChannel
                ) : (
                  <Skeleton />
                )}
              </TableCell>
            </TableRowLink>
          ))}
        </TableBody>
      </ResponsiveTable>
    </Card>
  );
};

export default CustomerSeenProducts;
