import CardTitle from "@dashboard/components/CardTitle/CardTitle";
import SearchBar from "@dashboard/components/SearchBar/SearchBar";
import { useProductListQuery } from "@dashboard/graphql";
import { FormChange } from "@dashboard/hooks/useForm";
import { Card, CardContent } from "@material-ui/core";
import React, { useState } from "react";

import ProductRelatedsList from "../ProductRelatedsList/ProductRelatedsList";
// Interface
interface GroupCategoriesProps {
  relateds: Array<{
    id: string;
  }>;
  onChange: FormChange<any>;
}
const ProductRelateds = ({ relateds, onChange }: GroupCategoriesProps) => {
  const [pagination, setPagination] = useState<{
    after?: string;
    before?: string;
    first?: number;
    last?: number;
  }>({ first: 20 });
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { data, loading, error } = useProductListQuery({
    variables: {
      ...pagination,
      hasChannel: false,
      hasSelectedAttributes: false,
      filter: {
        search: searchQuery,
      },
    },
  });

  const onAssignProduct = (product: any) => {
    const productID = product?.id;
    const alreadyExists = relateds?.some(
      selectedProduct => selectedProduct.id === productID,
    );

    if (alreadyExists) {
      return;
    }

    //
    onChange({
      target: {
        name: "oftenBoughtTogether",
        value: [...relateds, product],
      },
    });
  };

  const onUnassignProduct = (product: any) => {
    const productID = product?.id;

    //
    onChange({
      target: {
        name: "oftenBoughtTogether",
        value: relateds?.filter(
          selectedProduct => selectedProduct?.id !== productID,
        ),
      },
    });
  };

  const toggleAll = (products: any, type: "assign" | "unassign") => {
    if (type === "assign") {
      onChange({
        target: {
          name: "oftenBoughtTogether",
          value: [
            ...relateds,
            ...products
              ?.filter(
                product =>
                  !relateds?.some(
                    selectedProduct =>
                      selectedProduct?.id === product?.node?.id,
                  ),
              )
              ?.map(product => ({ ...product.node })),
          ],
        },
      });
    } else {
      onChange({
        target: {
          name: "oftenBoughtTogether",
          value: relateds?.filter(
            selectedProduct =>
              !products.some(
                product => product.node?.id === selectedProduct?.id,
              ),
          ),
        },
      });
    }
  };

  const setSelectedRelatedsToTop = (selecteds: any[], relateds: any[]) => [
    ...relateds,
    ...relateds?.filter(
      relatedProduct =>
        !selecteds.some(
          selectedProduct => selectedProduct.id === relatedProduct.node?.id,
        ),
    ),
  ];

  return (
    <Card>
      <CardTitle title={`Related (Often bought together)`} />
      <CardContent>
        <SearchBar
          allTabLabel={"All products"}
          currentTab={null}
          initialSearch={searchQuery}
          searchPlaceholder={"Search products"}
          tabs={[]}
          onAll={() => {}}
          onSearchChange={(value: string) => setSearchQuery(value)}
          onTabChange={() => {}}
          onTabDelete={() => {
            setSearchQuery("");
          }}
          onTabSave={() => {}}
        />
        {loading ? (
          "Loading..."
        ) : (
          <ProductRelatedsList
            products={setSelectedRelatedsToTop(relateds, data?.products?.edges)}
            selectedProducts={relateds ? relateds : []}
            disabled={error ? true : false}
            onAssignProduct={onAssignProduct}
            onUnassignProduct={onUnassignProduct}
            toggleAll={(products: any[], type: "assign" | "unassign") =>
              toggleAll(products, type)
            }
          />
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            marginTop: "2em",
          }}
        >
          {data?.products?.pageInfo?.hasPreviousPage && (
            <button
              style={{
                background: `rgba(37, 41, 41, 1)`,
                padding: "8px 14px",
                borderRadius: "6px",
                marginRight: "15px",
                marginLeft: "auto",
                color: "white",
                cursor: "pointer",
                fontSize: "15px",
              }}
              onClick={() => {
                setPagination({
                  before: data?.products?.pageInfo?.startCursor,
                  last: 20,
                });
              }}
            >
              Previous
            </button>
          )}
          {data?.products?.pageInfo?.hasNextPage && (
            <button
              style={{
                background: `rgba(37, 41, 41, 1)`,
                padding: "8px 14px",
                borderRadius: "6px",
                textTransform: "uppercase",
                color: "white",
                fontSize: "15px",
                cursor: "pointer",
              }}
              onClick={() => {
                setPagination({
                  after: data?.products?.pageInfo?.endCursor,
                  first: 20,
                });
              }}
            >
              Next
            </button>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default ProductRelateds;
