import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  sprinkles,
} from "@saleor/macaw-ui/next";
import React from "react";
import { Link } from "react-router-dom";

export const TopNavLink: React.FC<{
  to: string;
  variant?: "secondary" | "tertiary";
  direction?: "left" | "right";
  title?: string;
}> = ({ to, variant = "secondary", direction = "left", title = "" }) => (
  <Link to={to} className={sprinkles({ marginRight: 5 })}>
    <Button
      icon={direction === "left" ? <ArrowLeftIcon /> : <ArrowRightIcon />}
      variant={variant}
      size="large"
      title={title}
      data-test-id="app-header-back-button"
    />
  </Link>
);
