import { TopNav, TopNavLink } from "@dashboard/components/AppLayout/TopNav";
import CardSpacer from "@dashboard/components/CardSpacer";
import ImageSlider from "@dashboard/components/ImageSlider/ImageSlider";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import {
  OrderComplaintDataQuery,
  OrderErrorFragment,
} from "@dashboard/graphql";
import { useFlags } from "@dashboard/hooks/useFlags";
import { SubmitPromise } from "@dashboard/hooks/useForm";
import { renderCollection } from "@dashboard/misc";
import {
  OrderBothTypes,
  orderHasTransactions,
  OrderSharedType,
} from "@dashboard/orders/types";
import { orderComplaintListUrl, orderUrl } from "@dashboard/orders/urls";
import { Box } from "@material-ui/core";
import { ConfirmButtonTransitionState } from "@saleor/macaw-ui";
import React from "react";

import { ComplaintFinished } from "../ComplaintFinished/ComplaintFinished";
import OrderAmount from "../OrderRefundReturnAmount";
import { getReturnProductsAmountValues } from "../OrderRefundReturnAmount/utils";
import { SubmitCard } from "../OrderReturnPage/components";
import OrderRefundForm, {
  OrderRefundSubmitData,
} from "../OrderReturnPage/form";
import ItemsCard from "../OrderReturnPage/OrderReturnRefundItemsCard";
import {
  getFulfilledFulfillemnts,
  getParsedLines,
  getUnfulfilledLines,
  getWaitingFulfillments,
} from "../OrderReturnPage/utils";

export interface OrderReturnPageProps {
  order: OrderBothTypes;
  loading: boolean;
  errors?: OrderErrorFragment[];
  orderComplaintData: OrderComplaintDataQuery["returnable"];
  onSubmit: (data: OrderRefundSubmitData) => SubmitPromise;
  submitStatus: ConfirmButtonTransitionState;
}

const OrderComplaintPage: React.FC<OrderReturnPageProps> = props => {
  const {
    order,
    loading,
    errors = [],
    onSubmit,
    submitStatus,
    orderComplaintData,
  } = props;

  const { orderTransactions } = useFlags(["orderTransactions"]);

  if (!orderComplaintData) {
    return <></>;
  }

  return (
    <OrderRefundForm order={order} onSubmit={onSubmit}>
      {({ data, handlers, change, submit, isSaveDisabled }) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexBasis: { xs: "100%", md: "75%" },
            }}
          >
            <TopNav
              href={orderComplaintListUrl()}
              title={`${
                orderComplaintData?.returnType === "refund"
                  ? `Refund `
                  : "Complaint "
              } request for order #${order.number}`}
            >
              <TopNavLink
                direction="right"
                to={orderUrl(order?.id)}
                title="Order"
              />
            </TopNav>
            {order.status === "RETURNED" ? (
              <ComplaintFinished
                text={
                  orderComplaintData?.returnType === "refund"
                    ? `Refund request is done`
                    : "Complaint request is done"
                }
              />
            ) : (
              <>
                {" "}
                {!!data.unfulfilledItemsQuantities.length && (
                  <>
                    <ItemsCard
                      errors={errors}
                      order={order}
                      lines={getUnfulfilledLines(order as OrderSharedType)}
                      canReplace={
                        orderComplaintData?.returnType === "complaint"
                      }
                      itemsQuantities={data.unfulfilledItemsQuantities}
                      itemsSelections={data.itemsToBeReplaced}
                      onChangeQuantity={handlers.changeUnfulfiledItemsQuantity}
                      onSetMaxQuantity={
                        handlers.handleSetMaximalUnfulfiledItemsQuantities
                      }
                      onChangeSelected={handlers.changeItemsToBeReplaced}
                    />
                    <CardSpacer />
                  </>
                )}
                {renderCollection(
                  getWaitingFulfillments(order as OrderSharedType),
                  ({ id, lines }) => (
                    <React.Fragment key={id}>
                      <ItemsCard
                        errors={errors}
                        order={order}
                        fulfilmentId={id}
                        canReplace={
                          orderComplaintData?.returnType === "complaint"
                        }
                        lines={getParsedLines(lines)}
                        itemsQuantities={data.waitingItemsQuantities}
                        itemsSelections={data.itemsToBeReplaced}
                        onChangeQuantity={handlers.changeWaitingItemsQuantity}
                        onSetMaxQuantity={handlers.handleSetMaximalItemsQuantities(
                          id,
                        )}
                        onChangeSelected={handlers.changeItemsToBeReplaced}
                      />
                      <CardSpacer />
                    </React.Fragment>
                  ),
                )}
                {renderCollection(
                  getFulfilledFulfillemnts(order as OrderSharedType),
                  ({ id, lines }) => (
                    <React.Fragment key={id}>
                      <ItemsCard
                        errors={errors}
                        order={order}
                        fulfilmentId={id}
                        lines={getParsedLines(lines)}
                        canReplace={
                          orderComplaintData?.returnType === "complaint"
                        }
                        itemsQuantities={data.fulfilledItemsQuantities}
                        itemsSelections={data.itemsToBeReplaced}
                        onChangeQuantity={handlers.changeFulfiledItemsQuantity}
                        onSetMaxQuantity={handlers.handleSetMaximalItemsQuantities(
                          id,
                        )}
                        onChangeSelected={handlers.changeItemsToBeReplaced}
                      />
                      <CardSpacer />
                    </React.Fragment>
                  ),
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    minHeight: "30em",
                  }}
                >
                  <ImageSlider images={orderComplaintData?.media} />
                </div>
              </>
            )}
          </Box>
          <Box sx={{ width: "100%", flexBasis: { xs: "100%", md: "24%" } }}>
            <DetailPageLayout.RightSidebar>
              {orderHasTransactions(order, orderTransactions.enabled) ? (
                <SubmitCard
                  disabled={isSaveDisabled}
                  onSubmit={submit}
                  submitStatus={submitStatus}
                />
              ) : (
                <OrderAmount
                  allowNoRefund={orderComplaintData?.returnType === "complaint"}
                  noRefund={orderComplaintData?.returnType === "complaint"}
                  isReturn={orderComplaintData?.returnType === "complaint"}
                  amountData={getReturnProductsAmountValues(order, data)}
                  data={data}
                  order={order}
                  disableSubmitButton={isSaveDisabled}
                  disabled={loading}
                  errors={errors}
                  onChange={change}
                  onRefund={submit}
                />
              )}
            </DetailPageLayout.RightSidebar>
          </Box>
        </Box>
      )}
    </OrderRefundForm>
  );
};

export default OrderComplaintPage;
