import placeholderImg from "@assets/images/placeholder255x255.png";
import ActionDialog from "@dashboard/components/ActionDialog";
import useAppChannel from "@dashboard/components/AppLayout/AppChannelContext";
import { AttributeInput } from "@dashboard/components/Attributes";
import NotFoundPage from "@dashboard/components/NotFoundPage";
import { useShopLimitsQuery } from "@dashboard/components/Shop/queries";
import { WindowTitle } from "@dashboard/components/WindowTitle";
import {
  DEFAULT_INITIAL_SEARCH_DATA,
  VALUES_PAGINATE_BY,
} from "@dashboard/config";
import {
  ProductMediaCreateMutationVariables,
  useAddProductsToRelatedsMutation,
  useCreateProductRelatedsMutation,
  useProductDeleteMutation,
  useProductDetailsQuery,
  useProductMediaCreateMutation,
  useProductMediaDeleteMutation,
  useProductMediaReorderMutation,
  useRemoveProductsFromRelatedsMutation,
  useWarehouseListQuery,
} from "@dashboard/graphql";
import { getSearchFetchMoreProps } from "@dashboard/hooks/makeTopLevelSearch/utils";
import useNavigator from "@dashboard/hooks/useNavigator";
import useNotifier from "@dashboard/hooks/useNotifier";
import { commonMessages, errorMessages } from "@dashboard/intl";
import { useSearchAttributeValuesSuggestions } from "@dashboard/searches/useAttributeValueSearch";
import useCategorySearch from "@dashboard/searches/useCategorySearch";
import useCollectionSearch from "@dashboard/searches/useCollectionSearch";
import usePageSearch from "@dashboard/searches/usePageSearch";
import useProductSearch from "@dashboard/searches/useProductSearch";
import { useTaxClassFetchMore } from "@dashboard/taxes/utils/useTaxClassFetchMore";
import { getProductErrorMessage } from "@dashboard/utils/errors";
import useAttributeValueSearchHandler from "@dashboard/utils/handlers/attributeValueSearchHandler";
import createDialogActionHandlers from "@dashboard/utils/handlers/dialogActionHandlers";
import { mapEdgesToItems } from "@dashboard/utils/maps";
import { DialogContentText } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { getMutationState } from "../../../misc";
import ProductUpdatePage from "../../components/ProductUpdatePage";
import {
  productListUrl,
  productUrl,
  ProductUrlDialog,
  ProductUrlQueryParams,
  productVariantEditUrl,
} from "../../urls";
import {
  createImageReorderHandler,
  createImageUploadHandler,
} from "./handlers";
import { useProductUpdateHandler } from "./handlers/useProductUpdateHandler";
import { productUpdatePageMessages as messages } from "./messages";

interface ProductUpdateProps {
  id: string;
  params: ProductUrlQueryParams;
}

export const ProductUpdate: React.FC<ProductUpdateProps> = ({ id, params }) => {
  const navigate = useNavigator();
  const notify = useNotifier();
  const intl = useIntl();
  const [relatedProducts, setRelatedProducts] = useState<any>([]);
  const {
    loadMore: loadMoreCategories,
    search: searchCategories,
    result: searchCategoriesOpts,
  } = useCategorySearch({
    variables: DEFAULT_INITIAL_SEARCH_DATA,
  });
  const {
    loadMore: loadMoreCollections,
    search: searchCollections,
    result: searchCollectionsOpts,
  } = useCollectionSearch({
    variables: DEFAULT_INITIAL_SEARCH_DATA,
  });
  const {
    loadMore: loadMorePages,
    search: searchPages,
    result: searchPagesOpts,
  } = usePageSearch({
    variables: DEFAULT_INITIAL_SEARCH_DATA,
  });
  const {
    loadMore: loadMoreProducts,
    search: searchProducts,
    result: searchProductsOpts,
  } = useProductSearch({
    variables: DEFAULT_INITIAL_SEARCH_DATA,
  });
  const {
    loadMore: loadMoreAttributeValues,
    search: searchAttributeValues,
    result: searchAttributeValuesOpts,
    reset: searchAttributeReset,
  } = useAttributeValueSearchHandler(DEFAULT_INITIAL_SEARCH_DATA);

  const [createRelatedProducts] = useCreateProductRelatedsMutation({
    onCompleted: data => {
      if (data.createProductRelateds.errors.length === 0) {
        notify({
          status: "success",
          text: "You successfully added related products",
        });
        return;
      }
      notify({
        status: "error",
        text: data.createProductRelateds.errors[0]?.message,
      });
    },
  });
  const [updateRelatedProducts] = useAddProductsToRelatedsMutation({
    onCompleted: data => {
      if (data.addProductsToRelateds.errors.length === 0) {
        notify({
          status: "success",
          text: "You successfully updated related products",
        });
        return;
      }
      notify({
        status: "error",
        text: data.addProductsToRelateds.errors[0]?.message,
      });
    },
  });
  const [removeRelatedProducts] = useRemoveProductsFromRelatedsMutation({
    onCompleted: data => {
      if (data.removeProductsFromRelateds.errors.length === 0) {
        notify({
          status: "success",
          text: "You successfully removed related products",
        });
        return;
      }
      notify({
        status: "error",
        text: data.removeProductsFromRelateds.errors[0]?.message,
      });
    },
  });

  const { data, loading, refetch } = useProductDetailsQuery({
    displayLoader: true,
    variables: {
      id,
      firstValues: VALUES_PAGINATE_BY,
    },
  });

  const isSimpleProduct = !data?.product?.productType?.hasVariants;

  const { availableChannels } = useAppChannel(false);

  const limitOpts = useShopLimitsQuery({
    variables: {
      productVariants: true,
    },
  });

  const [reorderProductImages, reorderProductImagesOpts] =
    useProductMediaReorderMutation({});

  const [deleteProduct, deleteProductOpts] = useProductDeleteMutation({
    onCompleted: () => {
      notify({
        status: "success",
        text: intl.formatMessage({
          id: "vlVTmY",
          defaultMessage: "Product removed",
        }),
      });
      navigate(productListUrl());
    },
  });

  const [createProductImage, createProductImageOpts] =
    useProductMediaCreateMutation({
      onCompleted: data => {
        const imageError = data.productMediaCreate.errors.find(
          error =>
            error.field ===
            ("image" as keyof ProductMediaCreateMutationVariables),
        );
        if (imageError) {
          notify({
            status: "error",
            title: intl.formatMessage(errorMessages.imgageUploadErrorTitle),
            text: intl.formatMessage(errorMessages.imageUploadErrorText),
          });
        }
      },
    });

  const [deleteProductImage] = useProductMediaDeleteMutation({
    onCompleted: () =>
      notify({
        status: "success",
        text: intl.formatMessage(commonMessages.savedChanges),
      }),
  });

  const [openModal, closeModal] = createDialogActionHandlers<
    ProductUrlDialog,
    ProductUrlQueryParams
  >(navigate, params => productUrl(id, params), params);

  const product = data?.product;

  const getAttributeValuesSuggestions = useSearchAttributeValuesSuggestions();
  const warehousesQuery = useWarehouseListQuery({
    displayLoader: true,
    variables: {
      first: 50,
    },
  });

  const [createProductMedia, createProductMediaOpts] =
    useProductMediaCreateMutation({
      onCompleted: data => {
        const errors = data.productMediaCreate.errors;

        if (errors.length) {
          errors.map(error =>
            notify({
              status: "error",
              text: getProductErrorMessage(error, intl),
            }),
          );
        } else {
          notify({
            status: "success",
            text: intl.formatMessage(commonMessages.savedChanges),
          });
        }
      },
    });

  useEffect(() => {
    setRelatedProducts(
      product?.oftenBoughtTogether?.relateds?.edges?.map(
        product => product.node,
      ),
    );
  }, [product?.oftenBoughtTogether?.relateds?.edges]);

  const handleMediaUrlUpload = (mediaUrl: string) => {
    const variables = {
      alt: "",
      mediaUrl,
      product: product.id,
    };

    createProductMedia({
      variables,
    });
  };

  const handleBack = () => navigate(productListUrl());

  const handleImageDelete = (id: string) => () =>
    deleteProductImage({ variables: { id } });

  const [submit, submitOpts] = useProductUpdateHandler(product);

  const warehouses = React.useMemo(
    () => mapEdgesToItems(warehousesQuery.data?.warehouses) || [],
    [warehousesQuery.data],
  );

  const handleImageUpload = createImageUploadHandler(id, variables =>
    createProductImage({ variables }),
  );
  const handleImageReorder = createImageReorderHandler(product, variables =>
    reorderProductImages({ variables }),
  );

  const handleAssignAttributeReferenceClick = (attribute: AttributeInput) =>
    navigate(
      productUrl(id, {
        action: "assign-attribute-value",
        id: attribute.id,
      }),
      { resetScroll: false },
    );

  const onRelatedChange = async (data: any) => {
    // Check does user has oftenBOughtTogether, in case it doesnt has, that means we have to create
    // otherwise we have to update existing...
    const isCreate = !product.oftenBoughtTogether;

    if (isCreate) {
      if (
        !data?.oftenBoughtTogether ||
        data?.oftenBoughtTogether?.length === 0
      ) {
        return;
      }
      await createRelatedProducts({
        variables: {
          input: {
            product: product?.id,
            relateds: data?.oftenBoughtTogether?.map(
              (product: any) => product.id,
            ),
          },
        },
      });
      return;
    }

    const deletedRelatedProducts = product?.oftenBoughtTogether?.relateds?.edges
      ?.filter(
        ({ node: relatedProduct }) =>
          data?.oftenBoughtTogether?.some(
            product => product?.id === relatedProduct?.id,
          ) === false,
      )
      ?.map(({ node: relatedProduct }) => relatedProduct.id);

    if (deletedRelatedProducts?.length > 0) {
      await removeRelatedProducts({
        variables: {
          relatedsId: product.oftenBoughtTogether.id,
          products: deletedRelatedProducts,
        },
      });
    }

    const addedRelatedProducts = data?.oftenBoughtTogether
      ?.filter(
        (relatedProduct: any) =>
          product.oftenBoughtTogether?.relateds?.edges?.some(
            (product: any) => product.node?.id === relatedProduct?.id,
          ) === false,
      )
      ?.map(product => product.id);

    if (addedRelatedProducts?.length > 0) {
      await updateRelatedProducts({
        variables: {
          products: addedRelatedProducts,
          relatedsId: product.oftenBoughtTogether.id,
        },
      });
    }
  };

  const disableFormSave =
    submitOpts.loading ||
    createProductImageOpts.loading ||
    deleteProductOpts.loading ||
    reorderProductImagesOpts.loading ||
    createProductMediaOpts.loading ||
    loading;

  const formTransitionState = getMutationState(
    submitOpts.called,
    submitOpts.loading,
    submitOpts.errors,
    createProductMediaOpts.data?.productMediaCreate.errors,
  );

  const categories = mapEdgesToItems(searchCategoriesOpts?.data?.search) || [];

  const collections =
    mapEdgesToItems(searchCollectionsOpts?.data?.search) || [];

  const attributeValues =
    mapEdgesToItems(searchAttributeValuesOpts?.data?.attribute.choices) || [];

  const fetchMoreCollections = getSearchFetchMoreProps(
    searchCollectionsOpts,
    loadMoreCollections,
  );

  const fetchMoreCategories = getSearchFetchMoreProps(
    searchCategoriesOpts,
    loadMoreCategories,
  );

  const fetchMoreReferencePages = getSearchFetchMoreProps(
    searchPagesOpts,
    loadMorePages,
  );

  const fetchMoreReferenceProducts = getSearchFetchMoreProps(
    searchProductsOpts,
    loadMoreProducts,
  );

  const fetchMoreAttributeValues = {
    hasMore:
      !!searchAttributeValuesOpts.data?.attribute?.choices?.pageInfo
        ?.hasNextPage,
    loading: !!searchAttributeValuesOpts.loading,
    onFetchMore: loadMoreAttributeValues,
  };

  const { taxClasses, fetchMoreTaxClasses } = useTaxClassFetchMore();

  if (product === null) {
    return <NotFoundPage onBack={handleBack} />;
  }

  return (
    <>
      <WindowTitle title={data?.product?.name} />
      <ProductUpdatePage
        channels={availableChannels}
        productId={id}
        isSimpleProduct={isSimpleProduct}
        channelsErrors={submitOpts.channelsErrors}
        categories={categories}
        collections={collections}
        relatedProducts={relatedProducts}
        setRelatedProducts={setRelatedProducts as any}
        attributeValues={attributeValues}
        disabled={disableFormSave}
        errors={submitOpts.errors}
        variantListErrors={submitOpts.variantListErrors}
        fetchCategories={searchCategories}
        fetchCollections={searchCollections}
        fetchAttributeValues={searchAttributeValues}
        refetch={refetch}
        limits={limitOpts.data?.shop.limits}
        extraOnSubmit={onRelatedChange}
        saveButtonBarState={formTransitionState}
        media={data?.product?.media}
        header={product?.name}
        placeholderImage={placeholderImg}
        product={product}
        warehouses={warehouses}
        taxClasses={taxClasses ?? []}
        fetchMoreTaxClasses={fetchMoreTaxClasses}
        variants={product?.variants}
        onDelete={() => openModal("remove")}
        onImageReorder={handleImageReorder}
        onMediaUrlUpload={handleMediaUrlUpload}
        onSubmit={submit}
        onVariantShow={variantId =>
          navigate(productVariantEditUrl(product.id, variantId), {
            resetScroll: true,
          })
        }
        onImageUpload={handleImageUpload}
        onImageDelete={handleImageDelete}
        fetchMoreCategories={fetchMoreCategories}
        fetchMoreCollections={fetchMoreCollections}
        assignReferencesAttributeId={
          params.action === "assign-attribute-value" && params.id
        }
        onAssignReferencesClick={handleAssignAttributeReferenceClick}
        referencePages={mapEdgesToItems(searchPagesOpts?.data?.search) || []}
        referenceProducts={
          mapEdgesToItems(searchProductsOpts?.data?.search) || []
        }
        fetchReferencePages={searchPages}
        fetchMoreReferencePages={fetchMoreReferencePages}
        fetchReferenceProducts={searchProducts}
        fetchMoreReferenceProducts={fetchMoreReferenceProducts}
        fetchMoreAttributeValues={fetchMoreAttributeValues}
        onCloseDialog={() => navigate(productUrl(id), { resetScroll: false })}
        onAttributeSelectBlur={searchAttributeReset}
        onAttributeValuesSearch={getAttributeValuesSuggestions}
      />
      <ActionDialog
        open={params.action === "remove"}
        onClose={closeModal}
        confirmButtonState={deleteProductOpts.status}
        onConfirm={() => deleteProduct({ variables: { id } })}
        variant="delete"
        title={intl.formatMessage(messages.deleteProductDialogTitle)}
      >
        <DialogContentText>
          <FormattedMessage
            {...messages.deleteProductDialogSubtitle}
            values={{ name: product?.name }}
          />
        </DialogContentText>
      </ActionDialog>
    </>
  );
};
export default ProductUpdate;
