import { Button } from "@dashboard/components/Button";
import { Card, CardContent } from "@material-ui/core";
import React, { useEffect, useState } from "react";
interface Props {
  children?: any;
  title: string;
  onClick?: () => void;
  forceOpenedStatus?: boolean;
}
export const RevealContent = ({
  children,
  title = "",
  onClick = () => {}, //eslint-disable-line
  forceOpenedStatus = false,
}: Props) => {
  const [revealChildren, setRevealChildren] =
    useState<boolean>(forceOpenedStatus);

  useEffect(() => {
    setRevealChildren(forceOpenedStatus);
  }, [forceOpenedStatus]);

  return (
    <Card style={{ width: "100%" }}>
      <CardContent>
        <Button
          onClick={() => {
            setRevealChildren(reveal => !reveal);
            onClick();
          }}
          data-test-id="reveal-section"
          style={{
            width: "100%",
            display: "flex",
            alignItems: "start",
            justifyContent: "start",
            padding: "1em",
          }}
        >
          {title}
        </Button>
        {revealChildren ? children : null}
      </CardContent>
    </Card>
  );
};
export default RevealContent;
