import strikethroughIcon from "@dashboard/icons/StrikethroughIcon";
import Attaches from "@editorjs/attaches";
import { ToolConstructable, ToolSettings } from "@editorjs/editorjs";
import Embed from "@editorjs/embed";
import Header from "@editorjs/header";
import Image from "@editorjs/image";
import List from "@editorjs/list";
import Paragraph from "@editorjs/paragraph";
import Quote from "@editorjs/quote";
import createGenericInlineTool from "editorjs-inline-tool";

const inlineToolbar = ["link", "bold", "italic", "strikethrough"];

export const tools: Record<string, ToolConstructable | ToolSettings> = {
  embed: Embed,
  header: {
    class: Header,
    config: {
      defaultLevel: 1,
      levels: [1, 2, 3],
    },
    inlineToolbar,
  },
  list: {
    class: List,
    inlineToolbar,
  },
  quote: {
    class: Quote,
    inlineToolbar,
  },
  image: {
    class: Image,
    config: {
      endpoints: {
        byFile: "https://aqualing.blueduck.at/imageUpload",
        byUrl: "https://aqualing.blueduck.at/imageUpload",
      },
      uploader: {
        uploadByFile: async file => {
          const data = new FormData();
          data.append("image", file);
          /* eslint-disable */
          return await fetch(`https://aqualing.blueduck.at/imageUpload`, {
            method: "POST",
            body: data,
          }).then(async res => {
            const response = await res.json();

            return {
              success: 1,
              file: {
                url: response.file.url,
                // any other image data you want to store, such as width, height, color, extension, etc
              },
            };
          });
        },
      },
    },
    inlineToolbar,
  },
  attaches: {
    class: Attaches,
    config: {
      endpoints: {
        byFile: "https://aqualing.blueduck.at/fileUpload",
        byUrl: "https://aqualing.blueduck.at/fileUpload",
      },
      uploader: {
        uploadByFile: async file => {
          const data = new FormData();
          data.append("file", file);
          /* eslint-disable */
          return await fetch(`https://aqualing.blueduck.at/fileUpload`, {
            method: "POST",
            body: data,
          }).then(async res => {
            const response = await res.json();

            return {
              success: 1,
              file: {
                url: response?.file?.url,
                // any other image data you want to store, such as width, height, color, extension, etc
              },
            };
          });
          /* eslint-enable */
        },
      },
    },
    inlineToolbar,
  },
  paragraph: {
    class: Paragraph,
    inlineToolbar,
  },
  strikethrough: createGenericInlineTool({
    sanitize: {
      s: {},
    },
    shortcut: "CMD+S",
    tagName: "s",
    toolboxIcon: strikethroughIcon,
  }),
};
