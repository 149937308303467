import {
  ChannelVoucherData,
  createSortedVoucherData,
} from "@dashboard/channels/utils";
import useAppChannel from "@dashboard/components/AppLayout/AppChannelContext";
import { WindowTitle } from "@dashboard/components/WindowTitle";
import {
  FormData,
  GroupCreatePage,
} from "@dashboard/discounts/components/GroupCreatePage";
import { GroupCreateQueryParams, groupsUrl } from "@dashboard/discounts/urls";
import {
  addDiscountedCategoriesToGroup,
  CHANNELS,
} from "@dashboard/discounts/utils";
import {
  useAddUsersToGroupMutation,
  useCreateAndAddCategoryDiscountToGroupMutation,
  useCreateCustomerGroupMutation,
  useDeleteCustomerGroupMutation,
} from "@dashboard/graphql";
import useChannels from "@dashboard/hooks/useChannels";
import useNavigator from "@dashboard/hooks/useNavigator";
import useNotifier from "@dashboard/hooks/useNotifier/useNotifier";
import React, { useState } from "react";

import { VOUCHER_CREATE_FORM_ID } from "../VoucherCreate/types";
interface GroupCreateProps {
  params: GroupCreateQueryParams;
}
export const GroupCreate = ({ params }: GroupCreateProps) => {
  const [errors, setErrors] = useState<any[]>([]);
  const [createCustomerGroup] = useCreateCustomerGroupMutation();

  const [deleteCustomerGroup] = useDeleteCustomerGroupMutation();

  const [addUsersToGroup] = useAddUsersToGroupMutation();
  const [addDiscountedCategories] =
    useCreateAndAddCategoryDiscountToGroupMutation();

  const notify = useNotifier();
  const { availableChannels } = useAppChannel(false);
  const allChannels: ChannelVoucherData[] =
    createSortedVoucherData(availableChannels);
  const navigate = useNavigator();
  const { currentChannels } = useChannels(
    allChannels,
    params?.action,
    /* eslint-disable */
    { closeModal: () => {}, openModal: () => {} },
    { formId: VOUCHER_CREATE_FORM_ID },
    /* eslint-enable */
  );

  const handleSubmit = async (formData: FormData) => {
    try {
      // 1) Check if user didnt put somewhere value of discount (CATEGORIES)
      if (
        formData?.discountedCategories?.some(
          (category: any) =>
            +category.discountOptions?.value === 0 ||
            !category?.discountOptions?.value,
        )
      ) {
        notify({
          status: "warning",
          text: "Please add discount value for categories",
        });
        return;
      }

      // 2) Check if user didnt put somewhere value of discount (PRODUCTS)
      /** if (
        formData?.discountedProducts?.some(
          (product: any) =>
            +product.discountOptions?.value === 0 ||
            !product?.discountOptions?.value,
        )
      ) {
        notify({
          status: "warning",
          text: "Please add discount value for products",
        });
        return;
      } */

      setErrors([]);
      // 3) Create group
      const { data: createdGroup, errors = null } = await createCustomerGroup({
        variables: {
          input: {
            name: formData.name,
            limit: +formData?.limit > 0 ? formData?.limit : 0,
            channel: +formData?.channel || CHANNELS[0]?.id,
          },
        } as any,
      });

      // If group has errors, notice user
      if (
        createdGroup?.createCustomerGroup?.errors?.length > 0 ||
        errors !== null
      ) {
        setErrors(createdGroup.createCustomerGroup.errors);

        notify({
          status: "warning",
          text: "Maybe name already exists",
        });

        throw new Error("");
      }

      // 4) Add discounted categories

      if (formData?.discountedCategories?.length > 0) {
        const addedCategories = await addDiscountedCategoriesToGroup(
          createdGroup?.createCustomerGroup?.customerGroup?.id,
          formData?.discountedCategories,
          addDiscountedCategories,
          deleteCustomerGroup,
          notify,
        );

        if (!addedCategories) {
          return;
        }
      }

      /** // 5) Add discounted products

      if (formData?.discountedProducts?.length > 0) {
        const addedProducts = await addDiscountedProductsToGroup(
          createdGroup?.createCustomerGroup?.customerGroup?.id,
          formData?.discountedProducts,
          addDiscountedProducts,
          deleteCustomerGroup,
          notify,
        );

        if (!addedProducts) {
          return;
        }
      } */

      // 6) Add customers to group
      if (formData.customerEmails.length > 0) {
        const { data: addedUsers, errors: addUsersErrors } =
          await addUsersToGroup({
            variables: {
              customerEmails: formData.customerEmails as string[],
              id: createdGroup.createCustomerGroup.customerGroup.id as any,
            },
          });
        if (
          addedUsers?.addCustomersToGroup?.errors?.length > 0 ||
          addUsersErrors
        ) {
          deleteCustomerGroup({
            variables: {
              id: createdGroup.createCustomerGroup.customerGroup.id as any,
            },
          });
          throw new Error("users-not-added");
        }
      }

      // Everything is good
      notify({
        status: "success",
        text: "Successfully created group",
      });
      navigate(groupsUrl());
    } catch (err) {
      // alert(err);
    }
  };

  return (
    <>
      <WindowTitle title="Create Group" />
      <GroupCreatePage
        channelListings={currentChannels}
        errors={errors}
        listOfVouchers={[]}
        onSubmit={handleSubmit}
        params={params}
      />
    </>
  );
};

export default GroupCreate;
