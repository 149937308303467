/* eslint-disable */

export const CHANNELS = [
  { name: "B2B", id: "Q2hhbm5lbDoy" },
  { name: "Default", id: "Q2hhbm5lbDoz" },
];

export const addDiscountedCategoriesToGroup = async (
  groupID,
  categories,
  addDiscountedCategoriesMutation,
  deleteCustomerGroup,
  notify,
) => {
  if (
    !groupID ||
    !categories ||
    !addDiscountedCategoriesMutation ||
    !deleteCustomerGroup ||
    !notify
  )
    throw new Error();

  const handleSubmitAddDiscounteCategories = async (
    groupID: number,
    category: any,
  ) => {
    return await addDiscountedCategoriesMutation({
      variables: {
        input: {
          groupId: groupID,
          category: category?.id,
          value: category?.discountOptions?.value,
          valueType: category?.discountOptions?.valueType as any,
        },
      },
    })
      ?.then(res => {
        if (
          res.data.createAndAddCategoryDiscountToGroup.errors.length === 0 &&
          res.errors.length === 0
        ) {
          return true;
        }
      })
      .catch(() => false);
  };

  let finishedCategories = 0; // number of successfully added categories

  // Go through every category and send it to API (groupDiscountCategories)
  for (let i = 0; i < categories.length; i++) {
    const category = categories[i];
    await handleSubmitAddDiscounteCategories(+groupID, category);

    finishedCategories++;
  }

  // Not successfully added
  if (finishedCategories < categories.length) {
    notify({
      status: "error",
      text: "Error while adding discounted categories",
    });
    deleteCustomerGroup({
      variables: {
        id: groupID,
      },
    });
    return false;
  }

  return true;
};

export const addDiscountedProductsToGroup = async (
  groupID,
  products,
  addDiscountedProductsMutation,
  deleteCustomerGroup,
  notify,
) => {
  if (
    !groupID ||
    !products ||
    !addDiscountedProductsMutation ||
    !deleteCustomerGroup ||
    !notify
  )
    throw new Error();

  const handleSubmitAddDiscounteProducts = async (
    groupID: number,
    product: any,
  ) => {
    return await addDiscountedProductsMutation({
      variables: {
        input: {
          groupId: groupID,
          product: product?.id,
          value: product?.discountOptions?.value,
          valueType: product?.discountOptions?.valueType as any,
        },
      },
    })
      ?.then(res => {
        if (
          res.data.createAndAddProductDiscountToGroup.errors.length === 0 &&
          res.errors.length === 0
        ) {
          return false;
        }
      })
      .catch(() => false);
  };

  let finishedProducts = 0; // number of successfully added products

  // Go through every category and send it to API (groupDiscountProducts)
  for (let i = 0; i < products.length; i++) {
    const product = products[i];

    await handleSubmitAddDiscounteProducts(+groupID, product);

    finishedProducts++;
  }
  // Not successfully added
  if (finishedProducts < products.length) {
    notify({
      status: "error",
      text: "Error while adding discounted products",
    });
    deleteCustomerGroup({
      variables: {
        id: groupID as any,
      },
    });
    return false;
  }

  return true;
};

export const updateDiscountedCategoriesInGroup = async (
  groupID,
  categories,
  updateDiscountedCategoriesMutation,
  notify,
) => {
  if (!groupID || !categories || !updateDiscountedCategoriesMutation || !notify)
    throw new Error();

  const handleSubmitUpdateDiscountedCategories = async (
    groupID: number,
    category: any,
  ) => {
    return await updateDiscountedCategoriesMutation({
      variables: {
        id: category?.numberId,
        input: {
          groupId: groupID,
          value: category?.discountOptions?.value,
          valueType: category?.discountOptions?.valueType as any,
        },
      },
    })
      ?.then(res => {
        if (
          res.data.createAndAddCategoryDiscountToGroup.errors.length === 0 &&
          res.errors.length === 0
        ) {
          return true;
        }
      })
      .catch(() => {
        throw new Error("");
      });
  };

  let finishedCategories = 0; // number of successfully updated categories

  for (let i = 0; i < categories.length; i++) {
    const category = categories[i];
    await handleSubmitUpdateDiscountedCategories(+groupID, category);

    finishedCategories++;
  }

  // Not successfully updated
  if (finishedCategories < categories.length) {
    notify({
      status: "error",
      text: "Error while updating discounted categories",
    });
    return false;
  }

  return true;
};
