import { GroupsUrlSortField } from "@dashboard/discounts/urls";
import { CustomerGroupSortField } from "@dashboard/graphql";
import { createGetSortQueryVariables } from "@dashboard/utils/sort";

export function getSortQueryField(
  sort: GroupsUrlSortField,
): CustomerGroupSortField {
  switch (sort) {
    case GroupsUrlSortField.NAME:
      return CustomerGroupSortField.NAME;
    case GroupsUrlSortField.START_DATE:
      return CustomerGroupSortField.START_DATE;
    case GroupsUrlSortField.END_DATE:
      return CustomerGroupSortField.END_DATE;
    default:
      return undefined;
  }
}

export const getSortQueryVariables =
  createGetSortQueryVariables(getSortQueryField);
