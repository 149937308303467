import { makeStyles } from "@saleor/macaw-ui";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Media } from "../ImageSlider";

interface Props {
  activeIMG: Media;
  images: Media[];
  setActiveIMG: Dispatch<SetStateAction<Media>>;
}
const useStyles = makeStyles(
  () => ({
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: ".5em",
      maxWidth: "20%",
      margin: "0 auto",
      zIndex: "10",
    },
    BTN: {
      background: "none",
      boxShadow: "none",
      border: "none",
      outline: "none",
      cursor: "pointer",
      transition: "0.3s ease-in-out",
      maxWidth: "80px !important",
      maxHeight: "70px !important",
    },
    BTNactive: {
      borderBottom: "3px solid white",
      maxWidth: "80px !important",
      maxHeight: "70px !important",
    },
    active: {
      fontWeight: "bold",
      fontSize: "4rem",
    },
    icon: {
      transition: "0.3s ease-in-out",
    },
    img: {
      width: "100%",
      height: "100%",
      cursor: "pointer",
    },
  }),
  {
    name: "C:ProjektiAqualing dashaqualing-dashsrccomponentsImageSliderImagesMinimapImagesMinimap",
  },
);
export const ImagesMinimap = ({ activeIMG, images, setActiveIMG }: Props) => {
  const [minimapImages, setMinimapImages] = useState<Media[]>([]);
  const classes = useStyles();

  useEffect(() => {
    if (!images || !activeIMG) {
      return;
    }
    const activeIndex = images.findIndex(img => img.url === activeIMG.url);

    const firstImg = images[activeIndex - 1]
      ? images[activeIndex - 1]
      : undefined;
    const secondImg = images[activeIndex] ? images[activeIndex] : undefined;
    const thirdImg = images[activeIndex + 1]
      ? images[activeIndex + 1]
      : undefined;
    setMinimapImages([firstImg, secondImg, thirdImg].filter(val => val));
  }, [activeIMG, images]);

  return (
    <div className={classes.container}>
      <div
        style={{
          display: "flex",
          alignItems: "cenetr",
          gap: "5px",
          borderBottom: "1px solid white",
        }}
      >
        {minimapImages.map(img => (
          <div
            className={`${classes.BTN} ${
              img.url === activeIMG.url && classes.BTNactive
            }`}
            style={{ maxWidth: "80px", maxHeight: "80px" }}
            onClick={() => {
              setActiveIMG(img);
            }}
          >
            <img
              src={img.url}
              className={classes.img}
              width="100%"
              height="100%"
            />
          </div>
        ))}
      </div>
    </div>
  );
};
