import ActionDialog from "@dashboard/components/ActionDialog/ActionDialog";
import DeleteFilterTabDialog from "@dashboard/components/DeleteFilterTabDialog/DeleteFilterTabDialog";
import { SaveFilterTabDialogFormData } from "@dashboard/components/SaveFilterTabDialog";
import SaveFilterTabDialog from "@dashboard/components/SaveFilterTabDialog/SaveFilterTabDialog";
import { WindowTitle } from "@dashboard/components/WindowTitle";
import {
  businessRequestListUrl,
  BusinessRuquestListQueryParams,
  CustomerListUrlDialog,
} from "@dashboard/customers/urls";
import {
  useBulkRemoveCustomersMutation,
  useBusinessRequestsQuery,
} from "@dashboard/graphql";
import useBulkActions from "@dashboard/hooks/useBulkActions";
import useListSettings from "@dashboard/hooks/useListSettings";
import useNavigator from "@dashboard/hooks/useNavigator";
import useNotifier from "@dashboard/hooks/useNotifier/useNotifier";
import { usePaginationReset } from "@dashboard/hooks/usePaginationReset";
import usePaginator, {
  createPaginationState,
  PaginatorContext,
} from "@dashboard/hooks/usePaginator";
import { maybe } from "@dashboard/misc";
import { ListViews } from "@dashboard/types";
import createDialogActionHandlers from "@dashboard/utils/handlers/dialogActionHandlers";
import createFilterHandlers from "@dashboard/utils/handlers/filterHandlers";
import createSortHandler from "@dashboard/utils/handlers/sortHandler";
import { mapEdgesToItems } from "@dashboard/utils/maps";
import { getSortParams } from "@dashboard/utils/sort";
import { DialogContentText } from "@material-ui/core";
import { DeleteIcon, IconButton } from "@saleor/macaw-ui";
import React from "react";

import { BusinessRequestsListPage } from "../../components/BusinessRequestsListPage";
import {
  deleteFilterTab,
  getActiveFilters,
  getFilterOpts,
  getFilterQueryParam,
  getFiltersCurrentTab,
  getFilterTabs,
  getFilterVariables,
  saveFilterTab,
} from "../CustomerList/filters";
import { getSortQueryVariables } from "../CustomerList/sort";
interface BusinessRequestListParams {
  params: BusinessRuquestListQueryParams;
}
export const BusinessRequestList = ({ params }: BusinessRequestListParams) => {
  const navigate = useNavigator();
  const notify = useNotifier();
  const { updateListSettings, settings } = useListSettings(
    ListViews.CUSTOMER_LIST,
  );
  const { isSelected, listElements, reset, toggle, toggleAll } = useBulkActions(
    params.ids,
  );

  const paginationState = createPaginationState(settings.rowNumber, params);

  usePaginationReset(businessRequestListUrl, params, settings.rowNumber);
  const queryVariables = React.useMemo(
    () => ({
      ...paginationState,
      filter: getFilterVariables(params),
      sort: getSortQueryVariables(params as any),
    }),
    [params, settings.rowNumber], // eslint-disable-line
  );
  const { data, loading, refetch } = useBusinessRequestsQuery({
    displayLoader: true,
    variables: queryVariables as any,
  });

  const paginationValues = usePaginator({
    pageInfo: maybe(() => data?.bussinesRequests?.pageInfo),
    paginationState,
    queryString: params,
  });

  const tabs = getFilterTabs();

  const currentTab = getFiltersCurrentTab(params, tabs);

  const [changeFilters, resetFilters, handleSearchChange] =
    createFilterHandlers({
      cleanupFn: reset,
      createUrl: businessRequestListUrl,
      getFilterQueryParam,
      navigate,
      params,
    });

  const [openModal, closeModal] = createDialogActionHandlers<
    CustomerListUrlDialog,
    BusinessRuquestListQueryParams
  >(navigate, businessRequestListUrl, params);

  const handleTabChange = (tab: number) => {
    reset();
    navigate(
      businessRequestListUrl({
        activeTab: tab.toString(),
        ...getFilterTabs()[tab - 1]?.data,
      }),
    );
  };

  const handleTabDelete = () => {
    deleteFilterTab(currentTab);
    reset();
    navigate(businessRequestListUrl());
  };

  const handleTabSave = (data: SaveFilterTabDialogFormData) => {
    saveFilterTab(data.name, getActiveFilters(params));
    handleTabChange(tabs.length + 1);
  };

  const [bulkRemoveCustomers, bulkRemoveCustomersOpts] =
    useBulkRemoveCustomersMutation({
      onCompleted: data => {
        if (data.customerBulkDelete.errors.length === 0) {
          notify({
            status: "success",
            text: "save",
          });
          reset();
          refetch();
          closeModal();
        }
      },
    });
  const handleSort = createSortHandler(
    navigate,
    businessRequestListUrl,
    params,
  );

  return (
    <PaginatorContext.Provider value={paginationValues}>
      <WindowTitle title={"Business Requests"} />
      <BusinessRequestsListPage
        disableFiltering={false}
        currentTab={currentTab}
        filterOpts={getFilterOpts(params)}
        initialSearch={params.query || ""}
        onSearchChange={handleSearchChange}
        onFilterChange={changeFilters}
        onAll={resetFilters}
        onTabChange={handleTabChange}
        onTabDelete={() => openModal("delete-search")}
        onTabSave={() => openModal("save-search")}
        tabs={tabs.map(tab => tab.name)}
        businessRequests={mapEdgesToItems(data?.bussinesRequests)}
        settings={settings}
        disabled={loading}
        onUpdateListSettings={updateListSettings}
        onSort={handleSort}
        onCustomerSelect={null}
        toolbar={
          <IconButton
            variant="secondary"
            color="primary"
            onClick={() =>
              openModal("remove", {
                ids: listElements,
              })
            }
          >
            <DeleteIcon />
          </IconButton>
        }
        isChecked={(customer: any): any => isSelected(customer?.id)}
        selected={listElements.length}
        selectedCustomerIds={listElements}
        sort={getSortParams(params)}
        toggle={toggle}
        toggleAll={(customers: any[], selected) =>
          // onSelectAllCustomers(customers, selected, set);
          toggleAll(customers, selected)
        }
      />
      <ActionDialog
        open={params.action === "remove" && maybe(() => params.ids.length > 0)}
        onClose={closeModal}
        confirmButtonState={bulkRemoveCustomersOpts.status}
        onConfirm={() =>
          bulkRemoveCustomers({
            variables: {
              ids: params.ids,
            },
          })
        }
        variant="delete"
        title={"Delete requests (customers)"}
      >
        <DialogContentText>Are you sure you want delete it?</DialogContentText>
      </ActionDialog>
      <SaveFilterTabDialog
        open={params.action === "save-search"}
        confirmButtonState="default"
        onClose={closeModal}
        onSubmit={handleTabSave}
      />
      <DeleteFilterTabDialog
        open={params.action === "delete-search"}
        confirmButtonState="default"
        onClose={closeModal}
        onSubmit={handleTabDelete}
        tabName={maybe(() => tabs[currentTab - 1].name, "...")}
      />
    </PaginatorContext.Provider>
  );
};

export default BusinessRequestList;
