import { gql } from "@apollo/client";

export const orderListQuery = gql`
  query OrderList(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $filter: OrderFilterInput
    $sort: OrderSortingInput
  ) {
    orders(
      before: $before
      after: $after
      first: $first
      last: $last
      filter: $filter
      sortBy: $sort
    ) {
      edges {
        node {
          __typename
          billingAddress {
            ...Address
          }
          created
          id
          number
          collectionPoint {
            name
            id
          }
          paymentStatus
          status
          total {
            __typename
            gross {
              __typename
              amount
              currency
            }
          }
          userEmail
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

export const orderComplaintListQuery = gql`
  query OrderComplaintList(
    $sort: ProductReturnablesSortingInput
    $first: Int
    $after: String
    $last: Int
  ) {
    returnables(sortBy: $sort, first: $first, after: $after, last: $last) {
      edges {
        node {
          id
          name
          lineReturnables {
            ...OrderLine
          }
          order {
            ...OrderDetails
          }
          returnType
          returnMethod
          createdAt
          status
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

export const orderDraftListQuery = gql`
  query OrderDraftList(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $filter: OrderDraftFilterInput
    $sort: OrderSortingInput
  ) {
    draftOrders(
      before: $before
      after: $after
      first: $first
      last: $last
      filter: $filter
      sortBy: $sort
    ) {
      edges {
        node {
          __typename
          billingAddress {
            ...Address
          }
          created
          id
          number
          paymentStatus
          status
          total {
            __typename
            gross {
              __typename
              amount
              currency
            }
          }
          userEmail
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

export const orderDetailsQuery = gql`
  query OrderDetails($id: ID!) {
    order(id: $id) {
      ...OrderDetails
      pickup
      collectionPoint {
        name
        id
      }
      payments {
        gateway
        paymentMethodType
        order {
          id
        }
      }
      checkoutId
      user {
        email
        id
        usedGroupVoucher
        customerGroup {
          voucher {
            id
            code
            discountValueType
            discountValue
            channelListings {
              id
              channel {
                slug
              }
              discountValue
            }
          }
        }
      }
      valuta {
        type
        reason
        value
      }
    }
    shop {
      countries {
        code
        country
      }
      defaultWeightUnit
      fulfillmentAllowUnpaid
      fulfillmentAutoApprove
    }
  }
`;

export const orderFulfillData = gql`
  query OrderFulfillData($orderId: ID!) {
    order(id: $orderId) {
      id
      isPaid
      valuta {
        type
        reason
        value
      }
      deliveryMethod {
        __typename
        ... on ShippingMethod {
          id
        }
        ... on Warehouse {
          id
          clickAndCollectOption
        }
      }
      lines {
        ...OrderFulfillLine
      }
      number
    }
  }
`;

export const orderFulfillSettingsQuery = gql`
  query OrderFulfillSettings {
    shop {
      ...ShopOrderSettings
    }
  }
`;

export const orderSettingsQuery = gql`
  query OrderSettings {
    orderSettings {
      ...OrderSettings
    }
    shop {
      ...ShopOrderSettings
    }
  }
`;
export const orderRefundData = gql`
  query OrderRefundData($orderId: ID!) {
    order(id: $orderId) {
      id
      number
      total {
        gross {
          ...Money
        }
      }
      totalCaptured {
        ...Money
      }
      shippingPrice {
        gross {
          ...Money
        }
      }
      lines {
        ...RefundOrderLine
        quantityToFulfill
      }
      fulfillments {
        id
        status
        fulfillmentOrder
        lines {
          id
          quantity
          orderLine {
            ...RefundOrderLine
          }
        }
      }
    }
  }
`;

export const orderComplaintData = gql`
  query OrderComplaintData($id: Int) {
    returnable(id: $id) {
      id
      name
      lineReturnables {
        ...OrderLine
      }
      order {
        ...OrderDetails
      }
      media {
        url
      }
      returnType
      returnMethod
      createdAt
      status
    }
  }
`;

export const channelUsabilityData = gql`
  query ChannelUsabilityData($channel: String!) {
    products(channel: $channel) {
      totalCount
    }
  }
`;

export const defaultGraphiQLQuery = `query OrderDetails($id: ID!) { 
  order(id: $id) { 
    id
    number
    status
    isShippingRequired
    canFinalize
    created
    customerNote
    paymentStatus
    userEmail
    isPaid
  } 
}`;

export const warehousesListQuery = gql`
  query WarehousesList {
    warehouses(first: 100) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
