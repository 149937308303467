import { ProductReturnablesSortField } from "@dashboard/graphql";
import { OrderComplaintListUrlSortField } from "@dashboard/orders/urls";
import { createGetSortQueryVariables } from "@dashboard/utils/sort";

export const DEFAULT_SORT_KEY = OrderComplaintListUrlSortField.created;

export function getSortQueryField(
  sort: OrderComplaintListUrlSortField,
): ProductReturnablesSortField {
  switch (sort) {
    case OrderComplaintListUrlSortField.created:
      return ProductReturnablesSortField.CREATED_AT;
    case OrderComplaintListUrlSortField.orderStatus:
      return ProductReturnablesSortField.ORDER_STATUS;
    case OrderComplaintListUrlSortField.status:
      return ProductReturnablesSortField.STATUS;
    case OrderComplaintListUrlSortField.returnType:
      return ProductReturnablesSortField.RETURN_TYPE;
    default:
      return undefined;
  }
}

export const getSortQueryVariables =
  createGetSortQueryVariables(getSortQueryField);
