import {
  ChannelVoucherData,
  createSortedVoucherData,
} from "@dashboard/channels/utils";
import useAppChannel from "@dashboard/components/AppLayout/AppChannelContext";
import { WindowTitle } from "@dashboard/components/WindowTitle";
import {
  FormData,
  GroupCreatePage,
} from "@dashboard/discounts/components/GroupCreatePage";
import { GroupDetailsQueryParams, groupsUrl } from "@dashboard/discounts/urls";
import { addDiscountedCategoriesToGroup } from "@dashboard/discounts/utils";
import {
  DiscountTypes,
  useAddUsersToGroupMutation,
  useBulkCategoryDiscountUpdateMutation,
  useCreateAndAddCategoryDiscountToGroupMutation,
  useCustomerGroupQuery,
  useDeleteCustomerGroupMutation,
  useDeleteDiscountsMutation,
  useRemoveUserFromGroupMutation,
} from "@dashboard/graphql";
import useChannels from "@dashboard/hooks/useChannels";
import useNavigator from "@dashboard/hooks/useNavigator";
import useNotifier from "@dashboard/hooks/useNotifier/useNotifier";
import React, { useEffect, useState } from "react";

import { VOUCHER_CREATE_FORM_ID } from "../VoucherCreate/types";

interface GroupDetailsProps {
  params?: GroupDetailsQueryParams;
  id: string;
}
export const GroupDetails = ({ params, id }: GroupDetailsProps) => {
  const { data: customerGroup, refetch } = useCustomerGroupQuery({
    variables: {
      id: id as any,
    },
  });
  const [existingCustomers, setExistingCustomers] = useState([]);
  const [existingDiscountedCategories, setExistingDiscountedCategories] =
    useState([]);

  const [removeCustomerFromGroup] = useRemoveUserFromGroupMutation();
  const [addCustomersToGroup] = useAddUsersToGroupMutation();
  const [removeCustomerGroup] = useDeleteCustomerGroupMutation();
  const [addDiscountedCategories] =
    useCreateAndAddCategoryDiscountToGroupMutation();
  const [removeDiscountedCategories] = useDeleteDiscountsMutation();
  const [bulkCategoryDiscountUpdate] = useBulkCategoryDiscountUpdateMutation();

  const notify = useNotifier();
  const navigate = useNavigator();
  const { availableChannels } = useAppChannel(false);
  const allChannels: ChannelVoucherData[] =
    createSortedVoucherData(availableChannels);

  const { currentChannels } = useChannels(
    allChannels,
    params?.action,
    { closeModal: () => {}, openModal: () => {} },
    { formId: VOUCHER_CREATE_FORM_ID },
  );

  useEffect(() => {
    if (customerGroup?.customerGroup) {
      setExistingCustomers(
        customerGroup?.customerGroup?.customers.map(customer => customer.email),
      );
      setExistingDiscountedCategories(
        customerGroup?.customerGroup?.categoryDiscount?.edges?.map(
          category => ({
            id: category?.node?.category?.id,
            numberId: category?.node?.id,
            discountOptions: {
              value: category?.node?.value,
              valueType: category?.node?.valueType,
            },
          }),
        ),
      );
    }
  }, [customerGroup]);

  const onSubmit = async (formData: FormData) => {
    /// ///////////////////////////////////
    // FOR CUSTOMERS
    /// ///////////////////////////////////
    const deletedCustomers = existingCustomers.filter(
      existingEmail =>
        !formData.customerEmails.some(email => email === existingEmail),
    );
    const addedCustomers = formData.customerEmails.filter(
      email =>
        !existingCustomers.some(existingEmail => existingEmail === email),
    );

    if (deletedCustomers.length > 0) {
      deletedCustomers.map(async deletedEmail => {
        await removeCustomerFromGroup({
          variables: {
            customerEmail: deletedEmail,
            id: customerGroup?.customerGroup?.id as any,
          },
        });
      });
      setExistingCustomers(existingCustomers =>
        existingCustomers.filter(
          customerEmail =>
            !deletedCustomers.some(email => email === customerEmail),
        ),
      );
    }

    if (addedCustomers.length > 0) {
      addCustomersToGroup({
        variables: {
          customerEmails: addedCustomers as string[],
          id: customerGroup?.customerGroup?.id as any,
        },
      });
      setExistingCustomers(existingCustomers => [
        ...existingCustomers,
        ...addedCustomers,
      ]);
    }

    /// ///////////////////////////////////
    // FOR PRODUCTS
    /// ///////////////////////////////////
    /** const deletedDiscountedProducts = existingDiscountedProducts?.filter(
      existingDiscountedProduct =>
        !formData?.discountedProducts?.some(
          product => product.id === existingDiscountedProduct.id,
        ),
    );

    if (deletedDiscountedProducts.length > 0) {
      notify({
        status: "info",
        text: "Fali mutacija za REMOVE!",
      });
    }

    const addedDiscountedProducts = formData?.discountedProducts?.filter(
      product =>
        !existingDiscountedProducts?.some(
          existingDiscountedProduct =>
            existingDiscountedProduct.id === product.id,
        ),
    );

    // Check if user didnt put somewhere value of discount (PRODUCTS)
    if (
      addedDiscountedProducts?.some(
        (product: any) =>
          +product.discountOptions?.value === 0 ||
          !product?.discountOptions?.value,
      )
    ) {
      notify({
        status: "warning",
        text: "Please add discount value for products",
      });
      return;
    }

    if (addedDiscountedProducts?.length > 0) {
      addDiscountedProductsToGroup(
        customerGroup?.customerGroup?.id,
        addedDiscountedProducts,
        addDiscountedProducts,
        () => {},
        notify,
      );
    } */

    /// ///////////////////////////////////
    // FOR CATEGORIES
    /// ///////////////////////////////////

    const deletedDiscountedCategories = existingDiscountedCategories?.filter(
      existingDiscountedCategory =>
        !formData?.discountedCategories?.some(
          category => category.id === existingDiscountedCategory.id,
        ),
    );

    if (deletedDiscountedCategories.length > 0) {
      removeDiscountedCategories({
        variables: {
          ids: deletedDiscountedCategories?.map(category => category.numberId),
          type: DiscountTypes.CATEGORY_DISCOUNT,
        },
      });
    }

    const addedDiscountedCategories = formData?.discountedCategories?.filter(
      category =>
        !existingDiscountedCategories?.some(
          existingDiscountedCategory =>
            existingDiscountedCategory.id === category.id,
        ),
    );

    // 1) Check if user didnt put somewhere value of discount (CATEGORIES)
    if (
      addedDiscountedCategories?.some(
        (category: any) =>
          +category.discountOptions?.value === 0 ||
          !category?.discountOptions?.value,
      )
    ) {
      notify({
        status: "warning",
        text: "Please add discount value for categories",
      });
      return;
    }

    if (addedDiscountedCategories?.length > 0) {
      addDiscountedCategoriesToGroup(
        customerGroup?.customerGroup?.id,
        addedDiscountedCategories,
        addDiscountedCategories,
        () => {},
        notify,
      );
    }
    const updatedCategories = formData?.discountedCategories
      ?.filter(category => {
        const existingCategory = existingDiscountedCategories?.find(
          existingDiscountedCategory =>
            existingDiscountedCategory.id === category.id,
        );
        if (!existingCategory) {
          return false;
        }

        const isExistingCategoryChanged =
          category?.discountOptions?.value !==
            existingCategory?.discountOptions?.value ||
          category?.discountOptions?.valueType !==
            existingCategory?.discountOptions?.valueType;

        return isExistingCategoryChanged;
      })
      ?.map(category => {
        const existingCategory = existingDiscountedCategories?.find(
          existingDiscountedCategory =>
            existingDiscountedCategory.id === category.id,
        );
        return existingCategory
          ? { ...existingCategory, ...category }
          : category;
      })
      ?.map(category => ({
        ...category?.discountOptions,
        id: category?.numberId,
      }));

    if (updatedCategories?.length > 0) {
      bulkCategoryDiscountUpdate({
        variables: {
          groupId: +customerGroup?.customerGroup?.id,
          input: updatedCategories,
        },
      });
    }

    notify({ text: "Group is successfully edited", status: "success" });
    refetch({ id: +id });
  };

  const onRemove = async () => {
    const { data: removedGroup, errors } = await removeCustomerGroup({
      variables: {
        id: customerGroup?.customerGroup?.id as any,
      },
    });
    if (removedGroup?.deleteCustomerGroup?.errors.length > 0 || errors) {
      notify({
        status: "error",
        text: errors
          ? "Something went wrong"
          : removedGroup?.deleteCustomerGroup?.errors[0].message.slice(0, 150),
      });
      return;
    }
    notify({
      status: "success",
      text: "Group is deleted",
    });
    navigate(groupsUrl());
  };

  return (
    <>
      <WindowTitle title={customerGroup?.customerGroup?.name} />
      <GroupCreatePage
        errors={[]}
        title={customerGroup?.customerGroup?.name}
        groupCustomers={existingCustomers}
        params={{ ...params, id } as any}
        variant="update"
        initialFormData={
          {
            name: customerGroup?.customerGroup?.name,
            customerEmails: existingCustomers,

            groupID: customerGroup?.customerGroup?.id,
            channel: customerGroup?.customerGroup?.channel?.id,
            discountedCategories:
              customerGroup?.customerGroup?.categoryDiscount?.edges?.map(
                category => ({
                  id: category?.node?.category?.id,
                  discountOptions: {
                    value: category?.node?.value,
                    valueType: category?.node?.valueType,
                  },
                }),
              ) || [],
          } as any
        }
        onSubmit={onSubmit}
        listOfVouchers={[]}
        onDelete={onRemove}
        channelListings={currentChannels?.filter(
          channel => channel.name === "B2B",
        )}
      />
    </>
  );
};

export default GroupDetails;
