import ConfirmButton from "@dashboard/components/ConfirmButton";
import { WarehousesList } from "@dashboard/components/WarehousesList";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { DialogHeader } from "@saleor/macaw-ui";
import React, { useEffect, useState } from "react";

interface OrderPickupLocationProps {
  open: boolean;
  onClose: () => void;
  selectedLocation: any;
  confirmButtonState: any;
  onConfirm: (...params: any) => void;
}

export const OrderPickupLocation = (props: OrderPickupLocationProps) => {
  const { open, onClose, selectedLocation, confirmButtonState, onConfirm } =
    props;
  const [value, setValue] = useState<any>();

  useEffect(() => {
    if (selectedLocation) {
      setValue(selectedLocation);
    }
  }, [selectedLocation]);

  const exitModal = () => {
    onClose();
    // setAddressSearchState(defaultSearchState);
  };

  return (
    <Dialog open={open} onClose={exitModal} fullWidth>
      <DialogHeader onClose={exitModal}>Pickup locations</DialogHeader>
      <>
        <DialogContent>
          <WarehousesList
            selectedWarehouse={value}
            onChange={location => {
              setValue(location);
            }}
          />
        </DialogContent>
        <DialogActions>
          <ConfirmButton
            transitionState={confirmButtonState}
            variant="primary"
            type="submit"
            onClick={() => (value ? onConfirm(value) : null)}
          >
            Confirm
          </ConfirmButton>
        </DialogActions>
      </>
    </Dialog>
  );
};
