import { Button } from "@dashboard/components/Button";
import CardTitle from "@dashboard/components/CardTitle";
import Checkbox from "@dashboard/components/Checkbox/Checkbox";
import Grid from "@dashboard/components/Grid";
import Hr from "@dashboard/components/Hr";
import {
  AccountErrorFragment,
  CustomerDetailsFragment,
} from "@dashboard/graphql";
import { commonMessages } from "@dashboard/intl";
import { getFormErrors } from "@dashboard/utils/errors";
import getAccountErrorMessage from "@dashboard/utils/errors/account";
import {
  Box,
  Card,
  CardContent,
  // Checkbox,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@saleor/macaw-ui";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

const useStyles = makeStyles(
  theme => ({
    content: {
      paddingTop: theme.spacing(2),
    },
    hr: {
      margin: theme.spacing(3, 0),
    },
    pendingRequest: {
      color: "limegreen",
    },
    denyBTN: {
      background: "#EB2B59",
      borderColor: "#EB2B59",
      marginRight: "8px",
      color: "white",
      "&:hover": {
        color: "#EB2B59",
        background: "transparent",
        borderColor: "#EB2B59",
      },
    },
    sectionHeader: {
      marginBottom: theme.spacing(),
    },
  }),
  { name: "CustomerInfo" },
);

export interface CustomerInfoProps {
  customerDetails: CustomerDetailsFragment;
  acceptBusinessRequest: (customerEmail: string) => void;
  denyBusinessRequest: (customerEmail: string) => void;
  updateGeneralInformation: (data: any) => void;
  data: {
    firstName: string;
    lastName: string;
    email: string;
    UID: string;
    hasBusinessAccess: boolean;
    companyName: string;
    streetAddress: string;
    postalCode: string;
    city: string;
    personalPhone: string;
    businessPhone: string;
    numberOfWorkers: number;
    comment: string;
  };
  disabled: boolean;
  errors: AccountErrorFragment[];
  onChange: (event: React.ChangeEvent<any>) => void;
}

const CustomerInfo: React.FC<CustomerInfoProps> = props => {
  const {
    data,
    disabled,
    errors,
    onChange,
    customerDetails,
    acceptBusinessRequest,
    denyBusinessRequest,
    updateGeneralInformation,
  } = props;

  const classes = useStyles(props);
  const intl = useIntl();

  const formErrors = getFormErrors(
    [
      "firstName",
      "lastName",
      "email",
      "companyName",
      "streetAddress",
      "postalCode",
      "city",
      "personalPhone",
      "businessPhone",
      "numberOfWorkers",
      "comment",
      "UID",
    ],
    errors,
  );

  return (
    <Card>
      <CardTitle
        title={
          <FormattedMessage
            id="4v5gfh"
            defaultMessage="Account Information"
            description="account information, header"
          />
        }
      />
      <CardContent className={classes.content}>
        <Typography className={classes.sectionHeader}>
          <FormattedMessage {...commonMessages.generalInformations} />
        </Typography>
        <Grid variant="uniform">
          <TextField
            disabled={disabled}
            error={!!formErrors.firstName}
            fullWidth
            helperText={getAccountErrorMessage(formErrors.firstName, intl)}
            name="firstName"
            type="text"
            label={intl.formatMessage(commonMessages.firstName)}
            value={data.firstName}
            onChange={onChange}
            inputProps={{
              spellCheck: false,
            }}
          />
          <TextField
            disabled={disabled}
            error={!!formErrors.lastName}
            fullWidth
            helperText={getAccountErrorMessage(formErrors.lastName, intl)}
            name="lastName"
            type="text"
            label={intl.formatMessage(commonMessages.lastName)}
            value={data.lastName}
            onChange={onChange}
            inputProps={{
              spellCheck: false,
            }}
          />
          <TextField
            disabled={disabled}
            error={!!formErrors.personalPhone}
            fullWidth
            helperText={null}
            name="personalPhone"
            type="text"
            label={"Personal Phone"}
            value={data.personalPhone}
            onChange={onChange}
            inputProps={{
              spellCheck: false,
            }}
          />
        </Grid>
        <Hr className={classes.hr} />
        <Typography className={classes.sectionHeader}>
          <FormattedMessage
            id="SMakqb"
            defaultMessage="Contact Information"
            description="customer contact section, header"
          />
        </Typography>
        <TextField
          disabled={disabled}
          error={!!formErrors.email}
          fullWidth
          helperText={getAccountErrorMessage(formErrors.email, intl)}
          name="email"
          type="email"
          label={intl.formatMessage(commonMessages.email)}
          value={data.email}
          onChange={onChange}
          inputProps={{
            spellCheck: false,
          }}
        />
        {/** HAS BUSINESS ACCESS */}
        {(customerDetails as any)?.requestedBusinessAccess ? null : (
          <>
            {" "}
            <Hr className={classes.hr} />
            <Typography className={classes.sectionHeader}>
              <FormattedMessage
                id="PytviB"
                defaultMessage={`B2B status`}
                description="If user is b2b user"
              />
            </Typography>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "fit-content",
                marginRight: "auto",
              }}
            >
              <Typography className={classes.content}>
                <FormattedMessage
                  id="icMKQt"
                  defaultMessage="Has business access"
                  description="If user is b2b user"
                />
              </Typography>
              <Checkbox
                itemType="checkbox"
                value={data?.hasBusinessAccess}
                name="hasBusinessAccess"
                checked={data.hasBusinessAccess}
                onChange={() => {
                  onChange({
                    target: {
                      name: "hasBusinessAccess",
                      value: !data.hasBusinessAccess,
                    },
                  } as any);
                }}
              />
            </Box>
          </>
        )}
        {/** B2B INFO */}
        <Hr className={classes.hr} />
        <Typography className={classes.sectionHeader}>
          <FormattedMessage
            id="nMcjp2"
            defaultMessage={`B2B Informations`}
            description="If user is b2b user"
          />
          {(customerDetails as any)?.requestedBusinessAccess ? (
            <Typography className={classes.pendingRequest}>
              <FormattedMessage
                id="SBsAbw"
                defaultMessage={`(Request on pending)`}
                description="If user sent request and request is not aproved/denied"
              />
            </Typography>
          ) : null}
        </Typography>
        <Grid variant="uniform">
          <TextField
            disabled={
              disabled || (customerDetails as any)?.requestedBusinessAccess
            }
            error={!!formErrors.companyName}
            fullWidth
            helperText={null}
            name="companyName"
            type="text"
            label={"Company Name"}
            value={data?.companyName}
            onChange={onChange}
            inputProps={{
              spellCheck: false,
            }}
          />
          <TextField
            disabled={
              disabled || (customerDetails as any)?.requestedBusinessAccess
            }
            error={!!formErrors.streetAddress}
            fullWidth
            helperText={null}
            name="streetAddress"
            type="text"
            label={"Street Address"}
            value={data?.streetAddress}
            onChange={onChange}
            inputProps={{
              spellCheck: false,
            }}
          />
          <TextField
            disabled={
              disabled || (customerDetails as any)?.requestedBusinessAccess
            }
            error={!!formErrors.postalCode}
            fullWidth
            helperText={null}
            name="postalCode"
            type="number"
            label={"Postal Code"}
            value={data?.postalCode}
            onChange={onChange}
            inputProps={{
              spellCheck: false,
            }}
          />
          <TextField
            disabled={
              disabled || (customerDetails as any)?.requestedBusinessAccess
            }
            error={!!formErrors.city}
            fullWidth
            helperText={null}
            name="city"
            type="text"
            label={"City"}
            value={data?.city}
            onChange={onChange}
            inputProps={{
              spellCheck: false,
            }}
          />
          <TextField
            disabled={
              disabled || (customerDetails as any)?.requestedBusinessAccess
            }
            error={!!formErrors.city}
            fullWidth
            helperText={null}
            name="businessPhone"
            type="number"
            label={"Business Phone"}
            value={data?.businessPhone}
            onChange={onChange}
            inputProps={{
              spellCheck: false,
            }}
          />
          <TextField
            disabled={
              disabled || (customerDetails as any)?.requestedBusinessAccess
            }
            error={!!formErrors.numberOfWorkers}
            fullWidth
            helperText={null}
            name="numberOfWorkers"
            type="number"
            label={"Number Of Employees"}
            value={data?.numberOfWorkers}
            onChange={onChange}
            inputProps={{
              spellCheck: false,
            }}
          />
          <TextField
            disabled={
              disabled || (customerDetails as any)?.requestedBusinessAccess
            }
            error={!!formErrors.UID}
            fullWidth
            helperText={null}
            name="UID"
            type="number"
            label={"OIB"}
            value={data?.UID}
            onChange={onChange}
            inputProps={{
              spellCheck: false,
            }}
          />
          <TextField
            disabled={
              disabled || (customerDetails as any)?.requestedBusinessAccess
            }
            error={!!formErrors.comment}
            fullWidth
            helperText={null}
            name="comment"
            type="text"
            label={"Comment"}
            value={data?.comment}
            onChange={onChange}
            inputProps={{
              spellCheck: false,
            }}
          />
        </Grid>
        {(customerDetails as any)?.requestedBusinessAccess ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "1em",
            }}
          >
            <Button variant="secondary" className={classes.denyBTN}>
              <p
                title="Accept Request"
                onClick={() => denyBusinessRequest(data?.email)}
                style={{ padding: "0", minHeight: "0", margin: "0" }}
              >
                Deny Request
              </p>
            </Button>
            <Button variant="primary" className="">
              <p
                title="Accept Request"
                onClick={() => acceptBusinessRequest(data?.email)}
                style={{ padding: "0", minHeight: "0", margin: "0" }}
              >
                Accept Request
              </p>
            </Button>
          </Box>
        ) : (
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "1em",
            }}
          >
            <Button variant="primary" className="">
              <p
                title="Update b2b info"
                onClick={() => updateGeneralInformation(data)}
                style={{ padding: "0", minHeight: "0", margin: "0" }}
              >
                Update
              </p>
            </Button>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
CustomerInfo.displayName = "CustomerInfo";
export default CustomerInfo;
