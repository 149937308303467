import { TopNav } from "@dashboard/components/AppLayout/TopNav";
// Components
import { ListPageLayout } from "@dashboard/components/Layouts";
import { OrderComplaintListQuery } from "@dashboard/graphql";
import { OrderComplaintListUrlSortField } from "@dashboard/orders/urls";
// Other
import { PageListProps, SortPage } from "@dashboard/types";
// MUI
import { Card } from "@material-ui/core";
import React, { memo } from "react";

import OrderComplaintList from "../OrderComplaintList/OrderComplaintList";
export interface OrderComplaintListPageProps
  extends PageListProps,
    SortPage<OrderComplaintListUrlSortField> {
  disabled: boolean;
  orders: OrderComplaintListQuery["returnables"]["edges"];
}

export const OrderComplaintListPage = ({
  disabled,
  ...listProps
}: OrderComplaintListPageProps) => (
  <ListPageLayout>
    <TopNav title="Order complaints" />
    <Card>
      <OrderComplaintList disabled={disabled} {...listProps} />
    </Card>
  </ListPageLayout>
);
/* eslint-disable */
export default memo(OrderComplaintListPage);
/* eslint-enable */
