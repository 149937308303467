import Checkbox from "@dashboard/components/Checkbox";
import TextFieldWithChoice from "@dashboard/components/TextFieldWithChoice/TextFieldWithChoice";
import { DiscountTypeEnum } from "@dashboard/discounts/types";
import { DiscountValueTypeEnum } from "@saleor/sdk/dist/apollo/types";
import React, { useState } from "react";
// Icons
import { MdOutlineKeyboardDoubleArrowDown } from "react-icons/md";

import VoucherTypes from "../VoucherTypes/VoucherTypes";

// Interface
interface GroupCategoriesListProps {
  categories: Array<{
    id: string;
    name: string;
    slug: string;
    __typename: string;
    children: any;
  }>;
  selectedCategories: Array<{
    id: string;
    discountOptions: { value: string; valueType: DiscountValueTypeEnum };
  }>;
  disabled?: boolean;
  onAssignCategory: (category: any) => void;
  onUnassignCategory: (category: any) => void;
  toggleAll: (categories: any[], type: "assign" | "unassign") => void;
  updateCategory: (
    category: any,
    discountOptions: { value: number; valueType: DiscountValueTypeEnum },
  ) => void;
  bulkUpdateCategories: (
    categories: any[],
    discountOptions: { value?: number; valueType?: DiscountValueTypeEnum },
  ) => void;
}

const GroupCategoriesList = ({
  categories,
  disabled,
  selectedCategories = [],
  onAssignCategory = () => {},
  onUnassignCategory = () => {},
  updateCategory = () => {},
  bulkUpdateCategories = () => {},
}: GroupCategoriesListProps) => {
  // For bulk edit (for all categories)
  const [globalDiscountType, setGlobalDiscountType] =
    useState<DiscountTypeEnum>(DiscountTypeEnum.VALUE_PERCENTAGE);
  const [globalValue, setGlobalValue] = useState<number>(0);
  const [activeCategoryIdx, setActiveCategoryIdx] = useState<number | null>(
    null,
  );
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      {" "}
      {/** EDIT FOR ALL CATEGORIES AT SAME TIME */}
      <div style={{ marginBottom: "2em" }}>
        <button
          onClick={() => setShowModal(true)}
          className="bulk_edit"
          type="button"
          style={{
            background: "#006ab0",
            color: "white",
            padding: "7px 27px",
            borderRadius: "3em",
            border: "none",
            cursor: "pointer",
            marginTop: "2rem",
          }}
        >
          Bulk Edit
        </button>
        {showModal && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "fixed",
              flexDirection: "column",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              zIndex: 50,
            }}
          >
            <div
              onClick={() => setShowModal(false)}
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                zIndex: -1,
                background: "rgba(000,000,000,0.7)",
              }}
            ></div>
            <div
              style={{
                padding: "15px",
                background: "white",
                borderRadius: "32px",
              }}
            >
              <VoucherTypes
                title="Bulk edit"
                data={
                  {
                    discountType: globalDiscountType,
                  } as any
                }
                disabled={disabled}
                errors={[]}
                isVoucherForGroup
                onChange={event => {
                  event.stopPropagation();
                  setGlobalDiscountType(event?.target?.value);
                  bulkUpdateCategories(selectedCategories, {
                    valueType:
                      event?.target?.value === DiscountTypeEnum?.VALUE_FIXED
                        ? "FIXED"
                        : "PERCENTAGE",
                  });
                }}
              />
              <TextFieldWithChoice
                disabled={disabled}
                error={null}
                style={{
                  position: "relative",
                  width: "100%",
                }}
                ChoiceProps={{
                  label:
                    globalDiscountType === DiscountTypeEnum?.VALUE_PERCENTAGE
                      ? "%"
                      : "EUR",
                  name: "valueType",
                  values: null,
                }}
                label="Discount value"
                type="number"
                value={+globalValue}
                onChange={event => {
                  const { value } = event.target;
                  setGlobalValue(+value);
                  bulkUpdateCategories(selectedCategories, {
                    value: +value,
                  });
                }}
                inputProps={{
                  min: 0,
                }}
              />
              <button
                type="button"
                onClick={() => setShowModal(false)}
                className="bulk_edit"
                style={{
                  background: "#006ab0",
                  color: "white",
                  padding: "7px 27px",
                  borderRadius: "3em",
                  border: "none",
                  cursor: "pointer",
                  marginTop: "5px",
                  float: "right",
                }}
              >
                Save
              </button>
            </div>
          </div>
        )}
      </div>
      {/** TABLE */}
      <div style={{ minWidth: "650px", overflowX: "scroll" }}>
        {/** Table head */}
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "10% 40% 40% 10%",
            color: "rgba(37, 41, 41, 0.8)",
            fontWeight: "lighter",
            fontSize: "10px",
          }}
        >
          <h2>Checked</h2>
          <h2>Name</h2>
          <h2 style={{ textAlign: "center" }}>Current discount</h2>
          <h2 style={{ textAlign: "center" }}>Actions</h2>
        </div>
        {/** Table */}
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          {/** Categories */}
          {categories?.map((category, i) => {
            const isCategoryChecked = selectedCategories?.some(
              selectedCategory => selectedCategory?.id === category?.id,
            );
            const checkedCategoryDetails = isCategoryChecked
              ? selectedCategories?.find(
                  selectedCategory => selectedCategory?.id === category?.id,
                )
              : null;
            return (
              <div key={category?.id} style={{ padding: "0 3px" }}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "10% 40% 40% 10%",
                    border: "1px solid rgba(145, 157, 165, 0.3)",

                    borderRadius: "25px",
                    alignItems: "center",
                    background:
                      activeCategoryIdx === i ? "#006ab0" : "transparent",
                  }}
                >
                  {/** Number */}
                  <span
                    style={{
                      color: activeCategoryIdx === i ? "white" : "#919DA5",
                      fontSize: "15px",
                      textAlign: "center",
                    }}
                  >
                    <Checkbox
                      className="checbox"
                      disabled={disabled}
                      disableClickPropagation
                      checked={isCategoryChecked}
                      style={{ padding: "0 !important" }}
                      onChange={() => {
                        if (isCategoryChecked) {
                          onUnassignCategory(category);
                        } else {
                          // Assign
                          onAssignCategory(category);
                        }
                      }}
                    />
                  </span>
                  {/** Name */}
                  <span
                    style={{
                      color: activeCategoryIdx === i ? "white" : "#919DA5",
                      fontSize: "15px",
                    }}
                  >
                    {category?.name}
                  </span>
                  {/** No of subcategories */}
                  <span
                    style={{
                      fontSize: "15px",
                      color: activeCategoryIdx === i ? "white" : "#4C4D51",
                      textAlign: "center",
                    }}
                  >
                    {checkedCategoryDetails?.discountOptions?.value}
                    {checkedCategoryDetails?.discountOptions?.value &&
                    checkedCategoryDetails?.discountOptions?.valueType ===
                      "PERCENTAGE"
                      ? "%"
                      : "EUR"}
                  </span>
                  {/** Actions */}
                  <div style={{ textAlign: "center" }}>
                    <button
                      onClick={() =>
                        setActiveCategoryIdx(activeCategoryIdx =>
                          activeCategoryIdx === i ? null : i,
                        )
                      }
                      title="Expand"
                      type="button"
                      style={{
                        border: "none",
                        outline: "none",
                        cursor: "pointer",
                        borderRadius: "25px",
                      }}
                    >
                      <MdOutlineKeyboardDoubleArrowDown
                        style={{
                          fontSize: "24px",
                          color: "#006ab0",
                          transition: "0.3s all ease-in-out",
                          transform:
                            activeCategoryIdx === i ? "rotate(180deg)" : "",
                        }}
                      />
                    </button>
                  </div>
                </div>
                {/** Expander */}
                <div
                  style={{
                    transition: "0.4s all ease-in-out",
                    marginTop: "1em",
                    overflow: "hidden",
                    height: "100%",
                    maxHeight: activeCategoryIdx === i ? "50em" : "0",
                    display: "flex",
                    alignItems: "start",
                    gap: "2rem",
                  }}
                >
                  <VoucherTypes
                    data={
                      {
                        discountType:
                          checkedCategoryDetails?.discountOptions?.valueType ===
                          "PERCENTAGE"
                            ? DiscountTypeEnum.VALUE_PERCENTAGE
                            : DiscountTypeEnum.VALUE_FIXED,
                      } as any
                    }
                    disabled={disabled}
                    errors={[]}
                    isVoucherForGroup
                    onChange={event => {
                      event.stopPropagation();
                      updateCategory(category, {
                        valueType:
                          event.target?.value ===
                          DiscountTypeEnum?.VALUE_PERCENTAGE
                            ? "PERCENTAGE"
                            : "FIXED",
                        value: +checkedCategoryDetails?.discountOptions?.value,
                      });
                    }}
                  />
                  <div>
                    <h2 style={{ fontSize: "16px" }}>Value</h2>
                    <TextFieldWithChoice
                      disabled={disabled}
                      error={null}
                      ChoiceProps={{
                        label:
                          checkedCategoryDetails?.discountOptions?.valueType ===
                          "PERCENTAGE"
                            ? "%"
                            : "EUR",
                        name: "valueType",
                        values: null,
                      }}
                      label="Discount value"
                      type="number"
                      value={+checkedCategoryDetails?.discountOptions?.value}
                      onChange={event => {
                        const { value } = event.target;
                        updateCategory(category, {
                          valueType:
                            checkedCategoryDetails?.discountOptions?.valueType,
                          value: +value,
                        });
                      }}
                      inputProps={{
                        min: 0,
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default GroupCategoriesList;
