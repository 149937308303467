import { useUserPermissions } from "@dashboard/auth/hooks/useUserPermissions";
import Checkbox from "@dashboard/components/Checkbox";
import { hasPermissions } from "@dashboard/components/RequirePermissions";
import TableCellHeader from "@dashboard/components/TableCellHeader/TableCellHeader";
import TableHead from "@dashboard/components/TableHead";
import { TablePaginationWithContext } from "@dashboard/components/TablePagination";
import TableRowLink from "@dashboard/components/TableRowLink/TableRowLink";
import {
  BusinessRequestListUrlSortField,
  CustomerListUrlSortField,
  customerUrl,
} from "@dashboard/customers/urls";
import { BusinessRequestsQuery, PermissionEnum } from "@dashboard/graphql";
import { getUserName, renderCollection } from "@dashboard/misc";
import {
  ListActions,
  ListProps,
  RelayToFlat,
  SortPage,
} from "@dashboard/types";
import { getArrowDirection } from "@dashboard/utils/sort";
import { TableBody, TableCell, TableFooter } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { makeStyles, ResponsiveTable } from "@saleor/macaw-ui";
import React from "react";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(
  theme => ({
    [theme.breakpoints.up("lg")]: {
      colEmail: {},
      colName: {},
    },
    colEmail: {},
    colName: {
      paddingLeft: 0,
    },
    tableRow: {
      cursor: "pointer",
    },
  }),
  { name: "BusinessRequestsList" },
);

export interface BusinessRequestsListProps
  extends ListProps,
    ListActions,
    SortPage<CustomerListUrlSortField> {
  businessRequests: RelayToFlat<BusinessRequestsQuery["bussinesRequests"]>;
  onCustomerSelect: (customer: any) => void;
}
export const BusinessRequestsList: React.FC<
  BusinessRequestsListProps
> = props => {
  const {
    settings,
    disabled,
    businessRequests,
    onUpdateListSettings,
    onSort,
    toolbar,
    toggle,
    toggleAll,
    selected,
    sort,
    isChecked,
    onCustomerSelect = () => {}, //eslint-disable-line
  } = props;

  const userPermissions = useUserPermissions();

  const numberOfColumns = hasPermissions(userPermissions, [
    PermissionEnum.MANAGE_USERS,
  ])
    ? 4
    : 3;

  const classes = useStyles(props);

  return (
    <ResponsiveTable>
      <TableHead
        colSpan={numberOfColumns}
        selected={selected}
        disabled={disabled}
        items={businessRequests}
        toggleAll={toggleAll}
        toolbar={toolbar}
      >
        <TableCellHeader
          direction={
            sort.sort === (BusinessRequestListUrlSortField.name as any)
              ? getArrowDirection(sort.asc)
              : undefined
          }
          arrowPosition="right"
          onClick={() => onSort(CustomerListUrlSortField.name)}
          className={classes.colName}
        >
          <FormattedMessage id="Gr1SAu" defaultMessage="Customer Name" />
        </TableCellHeader>
        <TableCellHeader
          direction={
            sort.sort === CustomerListUrlSortField.email
              ? getArrowDirection(sort.asc)
              : undefined
          }
          onClick={() => onSort(CustomerListUrlSortField.email)}
          className={classes.colEmail}
        >
          <FormattedMessage id="97l2MO" defaultMessage="Customer Email" />
        </TableCellHeader>
        <TableCellHeader direction={undefined} className={classes.colEmail}>
          <FormattedMessage id="TvEZb1" defaultMessage="Company Name" />
        </TableCellHeader>
        <TableCellHeader direction={undefined} className={classes.colEmail}>
          <FormattedMessage id="b4Pain" defaultMessage="Company UID" />
        </TableCellHeader>
      </TableHead>
      <TableFooter style={{ width: "100%" }}>
        <TableRowLink>
          <TablePaginationWithContext
            colSpan={numberOfColumns}
            settings={settings}
            onUpdateListSettings={onUpdateListSettings}
          />
        </TableRowLink>
      </TableFooter>
      <TableBody>
        {renderCollection(
          businessRequests,
          customer => {
            const isSelected = customer ? isChecked(customer as any) : false;

            return (
              <TableRowLink
                className={!!customer ? classes.tableRow : undefined}
                hover={!!customer}
                key={customer ? customer.id : "skeleton"}
                selected={isSelected}
                href={
                  customer &&
                  customerUrl(customer.id, {
                    fromBusinessRequest: "true",
                  } as any)
                }
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isSelected}
                    disabled={disabled}
                    disableClickPropagation
                    onChange={() => {
                      toggle(customer.id);
                      onCustomerSelect(customer);
                    }}
                  />
                </TableCell>
                <TableCell className={classes.colName}>
                  {getUserName(customer)}
                </TableCell>
                <TableCell className={classes.colEmail}>
                  {customer?.email ?? <Skeleton />}
                </TableCell>
                <TableCell className={classes.colEmail}>
                  {customer?.generalInformation?.companyName ?? <Skeleton />}
                </TableCell>
                <TableCell className={classes.colEmail}>
                  {customer?.generalInformation?.UID ?? <Skeleton />}
                </TableCell>
              </TableRowLink>
            );
          },
          () => (
            <TableRowLink>
              <TableCell colSpan={numberOfColumns}>
                <FormattedMessage
                  id="ei948Z"
                  defaultMessage="No requests found"
                />
              </TableCell>
            </TableRowLink>
          ),
        )}
      </TableBody>
    </ResponsiveTable>
  );
};

export default BusinessRequestsList;
