import CardTitle from "@dashboard/components/CardTitle/CardTitle";
import { groupDetailsUrl } from "@dashboard/discounts/urls";
import { useRemoveUserFromGroupMutation } from "@dashboard/graphql";
import useNotifier from "@dashboard/hooks/useNotifier/useNotifier";
import { Button, Card, CardContent, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

// Interface
interface CustomerDetailsGroupProps {
  group: {
    id: string | number;
    name: string;
  };
  customerEmail: string;
  onSubmit: () => void;
}
const CustomerDetailsGroup = ({
  group,
  customerEmail,
  onSubmit = () => {},
}: CustomerDetailsGroupProps) => {
  const notify = useNotifier();
  const [removeCustomerFromGroup] = useRemoveUserFromGroupMutation({
    onCompleted: data => {
      if (data?.removeCustomerFromGroup?.errors?.length > 0) {
        notify({
          text: data?.removeCustomerFromGroup?.errors[0]?.message,
          status: "error",
        });
        return;
      }
      notify({
        text: "Customer is successfully removed from group",
        status: "success",
      });
      if (onSubmit) {
        onSubmit();
      }
    },
  });

  return (
    <Card>
      <CardTitle title="Customer group" />
      <CardContent>
        <Typography variant="h4">
          Name:{" "}
          <Link
            style={{ textDecoration: "underline" }}
            title="See group details"
            to={groupDetailsUrl(group?.id as string)}
          >
            {group?.name}
          </Link>
        </Typography>
        <Button
          style={{ marginTop: "15px" }}
          onClick={() => {
            if (!group?.id) {
              return;
            }
            removeCustomerFromGroup({
              variables: {
                id: +group?.id,
                customerEmail,
              },
            });
          }}
          title="Remove customer from group"
        >
          Remove customer from group
        </Button>
      </CardContent>
    </Card>
  );
};

export default CustomerDetailsGroup;
