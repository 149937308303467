import CardTitle from "@dashboard/components/CardTitle";
import { ControlledCheckbox } from "@dashboard/components/ControlledCheckbox";
import { Grid } from "@dashboard/components/Grid";
import { DiscountErrorFragment } from "@dashboard/graphql";
import { getFormErrors } from "@dashboard/utils/errors";
import getDiscountErrorMessage from "@dashboard/utils/errors/discounts";
import { Card, CardContent, TextField, Typography } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";

import { VoucherDetailsPageFormData } from "../VoucherDetailsPage";
import messages from "./messages";
import { useStyles } from "./styles";

interface VoucherLimitsProps {
  data: VoucherDetailsPageFormData;
  disabled: boolean;
  errors: DiscountErrorFragment[];
  initialUsageLimit: number;
  onChange: (event: React.ChangeEvent<any>) => void;
  setData: (data: Partial<VoucherDetailsPageFormData>) => void;
  isNewVoucher: boolean;
}

const VoucherLimits = ({
  data,
  disabled,
  errors,
  initialUsageLimit,
  onChange,
  setData,
  isNewVoucher,
}: VoucherLimitsProps) => {
  const intl = useIntl();
  const classes = useStyles();

  const formErrors = getFormErrors(["usageLimit"], errors);

  const usesLeft = data.usageLimit - data.used;

  return (
    <Card>
      <CardTitle title={intl.formatMessage(messages.usageLimitsTitle)} />
      <CardContent className={classes.cardContent}>
        <ControlledCheckbox
          testId="has-usage-limit"
          checked={
            data.hasUsageLimit || (data as any)?.voucherInput?.hasUsageLimit
          }
          label={intl.formatMessage(messages.hasUsageLimit)}
          name={"hasUsageLimit" as keyof VoucherDetailsPageFormData}
          onChange={evt => {
            onChange(evt);
            setData({ usageLimit: initialUsageLimit });
          }}
        />
        {data.hasUsageLimit ||
          ((data as any)?.voucherInput?.hasUsageLimit &&
            (isNewVoucher ? (
              <TextField
                data-test-id="usage-limit"
                disabled={disabled}
                error={!!formErrors.usageLimit || data.usageLimit <= 0}
                helperText={getDiscountErrorMessage(
                  formErrors.usageLimit,
                  intl,
                )}
                label={intl.formatMessage(messages.usageLimit)}
                name={"usageLimit" as keyof VoucherDetailsPageFormData}
                value={
                  data.usageLimit || (data as any)?.voucherInput?.usageLimit
                }
                onChange={onChange}
                type="number"
                fullWidth
                inputProps={{
                  min: 1,
                }}
              />
            ) : (
              <Grid variant="uniform">
                <TextField
                  data-test-id="usage-limit"
                  disabled={disabled}
                  error={!!formErrors.usageLimit || data.usageLimit <= 0}
                  helperText={getDiscountErrorMessage(
                    formErrors.usageLimit,
                    intl,
                  )}
                  label={intl.formatMessage(messages.usageLimit)}
                  name={"usageLimit" as keyof VoucherDetailsPageFormData}
                  value={
                    data.usageLimit ||
                    (data as any)?.voucherInput?.usageLimit ||
                    ""
                  }
                  onChange={onChange}
                  type="number"
                  inputProps={{
                    min: 1,
                  }}
                />
                <div className={classes.usesLeftLabelWrapper}>
                  <Typography variant="caption">
                    {intl.formatMessage(messages.usesLeftCaption)}
                  </Typography>
                  <Typography>{usesLeft >= 0 ? usesLeft : 0}</Typography>
                </div>
              </Grid>
            )))}
        <ControlledCheckbox
          testId="apply-once-per-customer"
          checked={
            data.applyOncePerCustomer ||
            (data as any)?.voucherInput?.applyOncePerCustomer
          }
          label={intl.formatMessage(messages.applyOncePerCustomer)}
          name={"applyOncePerCustomer" as keyof VoucherDetailsPageFormData}
          onChange={onChange}
        />
        <ControlledCheckbox
          testId="only-for-staff"
          checked={
            data.onlyForStaff || (data as any)?.voucherInput?.onlyForStaff
          }
          label={intl.formatMessage(messages.onlyForStaff)}
          name={"onlyForStaff" as keyof VoucherDetailsPageFormData}
          onChange={onChange}
        />
      </CardContent>
    </Card>
  );
};
export default VoucherLimits;
