import BackArrow from "@material-ui/icons/ArrowBackIosOutlined";
import NextArrow from "@material-ui/icons/ArrowForwardIosOutlined";
import { makeStyles } from "@saleor/macaw-ui";
import React, { useEffect, useState } from "react";

import { ImagesMinimap } from "./ImagesMinimap/ImagesMinimap";
export interface Media {
  url: string;
  __typename: string;
}
interface Props {
  images: Media[];
}

const useStyles = makeStyles(
  theme => ({
    settings: {
      marginRight: theme.spacing(2),
    },
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      width: "100%",
      position: "relative",
      maxWidth: "50%",
      [theme.breakpoints.down("md")]: {
        maxWidth: "80%",
      },
    },
    imgContainer: {
      width: "100%",
      minHeight: "30em",
      marginBottom: "2em",
      position: "relative",
      [theme.breakpoints.down("md")]: {
        minHeight: "15em",
      },
    },
    activeIMG: {
      cursor: "pointer",
      maxHeight: "30em",
    },
    bigViewImgContainer: {
      width: "100%",
      maxWidth: "40%",
      minHeight: "30em",
      marginBottom: "2em",
      display: "flex",
      alignItems: "center",
      position: "relative",
      justifyContent: "center",
      [theme.breakpoints.down("md")]: {
        maxWidth: "80%",
        minHeight: "20em",
      },
    },
    arrowBtn: {
      position: "absolute",
      top: "50%",
      zIndex: "12",
      background:
        theme.palette.type === "dark"
          ? "rgba(225,225,225,0.2)"
          : "rgba(37, 41, 41, 1)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "7px",
      cursor: "pointer",
    },
    arrowIcon: {
      color: theme.palette.type === "dark" ? "rgba(37,41,41,1)" : "white",
    },
  }),
  { name: "ImageSlider" },
);

export const ImageSlider = ({ images }: Props) => {
  const [activeIMG, setActiveIMG] = useState<Media>(images[0]);
  const [openBigView, setOpenBigView] = useState<boolean>(false);
  const classes = useStyles();
  useEffect(() => {
    if (images?.length === 0) {
      return;
    }
    setActiveIMG(images[0]);
    // setClassname("opacity-100");
  }, [images]);

  return (
    <div className={`${classes.container}`}>
      {/** ACTIVE IMAGE */}

      {openBigView ? null : (
        <div className={classes.imgContainer}>
          <div
            className={`${classes.arrowBtn}`}
            style={{
              left: "-2em",
            }}
            onClick={e => {
              e.stopPropagation();
              const activeImgIndex = images.findIndex(
                img => img.url === activeIMG.url,
              );

              const newImg = images[activeImgIndex - 1]
                ? images[activeImgIndex - 1]
                : images[images.length - 1];
              setActiveIMG(newImg);
            }}
          >
            <BackArrow
              className={classes.arrowIcon}
              style={{ fontSize: "20px" }}
            />
          </div>
          <img
            src={activeIMG?.url}
            className={classes.activeIMG}
            alt="Malfunction"
            onClick={() => setOpenBigView(true)}
            width="100%"
            height="100%"
          />
          <div
            className={`${classes.arrowBtn}`}
            style={{
              right: "-2em",
            }}
            onClick={e => {
              e.stopPropagation();

              const activeImgIndex = images.findIndex(
                img => img.url === activeIMG.url,
              );

              setActiveIMG(
                images[activeImgIndex + 1]
                  ? images[activeImgIndex + 1]
                  : images[0],
              );
            }}
          >
            <NextArrow
              className={classes.arrowIcon}
              style={{ fontSize: "20px" }}
            />
          </div>
        </div>
      )}

      {openBigView ? null : (
        <ImagesMinimap
          images={images}
          activeIMG={activeIMG}
          setActiveIMG={setActiveIMG}
        />
      )}

      {openBigView && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(000,000,000,0.6)",
            zIndex: 15,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: "0 1.5em",
          }}
        >
          <div
            onClick={() => setOpenBigView(false)}
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              zIndex: 10,
            }}
          ></div>
          <div className={classes.bigViewImgContainer}>
            <div
              className={`${classes.arrowBtn}`}
              style={{
                left: "-2em",
              }}
              onClick={e => {
                e.stopPropagation();
                const activeImgIndex = images.findIndex(
                  img => img.url === activeIMG.url,
                );

                const newImg = images[activeImgIndex - 1]
                  ? images[activeImgIndex - 1]
                  : images[images.length - 1];
                setActiveIMG(newImg);
              }}
            >
              <BackArrow
                className={classes.arrowIcon}
                style={{ fontSize: "20px" }}
              />
            </div>
            <img
              src={activeIMG?.url}
              className={classes.activeIMG}
              alt="Malfunction"
              width="100%"
              height="100%"
            />
            <div
              className={`${classes.arrowBtn}`}
              style={{
                right: "-2em",
              }}
              onClick={e => {
                e.stopPropagation();

                const activeImgIndex = images.findIndex(
                  img => img.url === activeIMG.url,
                );

                setActiveIMG(
                  images[activeImgIndex + 1]
                    ? images[activeImgIndex + 1]
                    : images[0],
                );
              }}
            >
              <NextArrow
                className={classes.arrowIcon}
                style={{ fontSize: "20px" }}
              />
            </div>
          </div>
          <div style={{ position: "relative", marginTop: "3em", zIndex: "10" }}>
            <ImagesMinimap
              images={images}
              activeIMG={activeIMG}
              setActiveIMG={setActiveIMG}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageSlider;
